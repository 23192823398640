body {background-color: #050719; color: #ffffff; font-size: 20px;margin: 0px;padding: 0px;}
.btn-color {background-color: #8fff00; color: #ffffff}/*edit button on the top navbar*/
.fees:hover{
    color: #8fff00;

}
/* container-fluid */


nav {width:100%;margin: 0px}
.navbar-light .navbar-toggler-icon{
    background-image: url(data:image/svg+xml,%3csvgxmlns=%27http://www.w3.org/2000/svg%27viewBox=%27003030%27%3e%3cpathstroke=%27rgba%143,0,0,0.55%29%27stroke-linecap=%27round%27stroke-miterlimit=%2710%27stroke-width=%272%27d=%27M47h22M415h22M423h22%27/%3e%3c/svg%3e);;
}
.nav-item-style {position: absolute; background-color: #050719;}/*style links on the top navbar*/
.nav-color {background-color: #050719;opacity: 90%}/*add background color to navbar*/
.nav-item-color {color: #ffffff;} /*color of top navbar link in unvisited state*/
.nav-item a:hover {color: #8fff00;}/*color of top navbar link when the used hovers over it*/
.nav-item a:active {color: #8fff00;}/*color of top navbar link when the user clicks it*/
.nav-item a:visited {color: #ffffff;}/*color of top navbar link after it has been clicked*/
/*color of navbar dropdown botton on mobile*/
.navbar-collapse-style {z-index: 1;}/*cause the top navbar dropdown to be one layer above the section*/
/*style the dropdown page. (The dropdown page is called 'modal'*/
.modal-styles {background-color: #050719;}
.modal-styles h3 {color: #ffffff;}
.modal-styles h4 {color: #8fff00;}
.modal-styles td {color:#8fff00}
.modal-btn {background-color: #000000; color: #8fff00; border: #8fff00 2px solid;}
.modal-position {position: relative; top:100px;}
.modal-foot {text-align: center; display: none;}
.modal-head1 {display: none;}
.modal-head1 h3 {text-decoration: underline #0000ff 3px; color:#ffffff; position: relative; left: 3%}
.modal-link-style {font-size: 20px; color: #ffffff; text-decoration: none;}
.btn-style {color: #8fff00; background-color: #050719;}
/*style the <section>. Note that section1, section2, etc... represent the different rows in the section in order from top to buttom*/
.section1-row-style {position: relative; top: 150px; z-index: 0;}
.section2-row-style {background-color: #1b1b33; padding-top: 5%; padding-left: 10%; padding-right: 10%;}
.section3-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%;}

.section5-row-style {padding-top: 5%; padding-left: 2%; padding-right: 2%;}
.section3-img-style .section5-img-style {width: 50%;}
.col-padding-5 {padding: 5%; font-size:larger}
/*styling of 3 cards (still in the <section>)*/
.cards-color {background-color: #050719;}

.card-shadow { margin: 15%}
/*styling of <footer>. Note that the footer has 3 rows. The one with the image and blue background, the one with
social media icons and victoi logo and the one with the links at the and a blue button on the left.*/
footer{position: relative; top: 200px; margin: auto;}
.footer-row1-style {border-radius: 25px; background-color: #0000ff; height: 50vh;}
.footer-row2 {
    margin: 50px auto;
}
.footer-logo {width: 50px; margin: auto;}
.btn-group-style {width: 15%; height: 40px;}
.btn-primary-style {border:solid 1px #ffffff}
.img2-style {position: relative; width:120%; bottom: 40%; right: 20%;}
.img2-txt-dsc-style {position: relative; bottom: 0%}
.app-download-container-style {border-radius: 10px; background-color:#ffffff; padding: 10px;}

.footer-row3-style {border-top: solid 1px rgb(255, 255, 255);}
/*additional styles on mobile devices for responsive view*/
@media screen and (min-width:480px){
    .H-img{
        padding-bottom:30px;

    }

}
@media screen and (max-width:480px) {
    /*styling of entire body*/
    .H-img{
        padding-bottom:30px;


    }

    body{font-size: 16px;}
    /*Style navbar and dropdown page*/
    .hide-content {display: none;}/*hide barcode under the top navbar at the page intro on homepage*/
    .modal-foot {display: block;}
    /*style <section> of the page*/
    .section2-row-style {background-color: #1b1b33; padding-top: 5%; padding-left: 10%; padding-right: 10%;}
    .section3-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; text-align: center;}

    .section5-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; text-align: center;}
    .section3-img-style, .section4-img-style, .section5-img-style {width: auto;}
    /*style the <footer>*/
    .footer-row1-style {border-radius: 25px; background-color: #0000ff; height: 500px; margin: auto;}
    .footer-row1-txt-style {position: relative; bottom: 150px;}
    .SM-img-size {width: 25px;}/*SM Means Social media. This is the styling of social media icons at the footer*/
    .footer-logo {width: 30px;}/*This is the styling victoi logo at the footer*/
    .btn-group-style {margin-left: 30%;}/*style the blue button at the extreme end of the footer*/
}
.controle{
    padding: 0 10% !important;
}

@media screen and (max-width:480px) {
    .controle{
        padding: 0 7% !important;
        /* border: 1px solid white !important; navbar-brand*/
    }
    /*styling of entire body*/
    body{font-size: 16px;}
    .H-img{
        gap: 50px;

    }
    /*Style navbar and dropdown page*/
    .hide-content {display: none;}/*hide barcode under the top navbar at the page intro on homepage*/
    .modal-foot {display: block;}
    /*style <section> of the page*/
    .section2-row-style { padding-top: 30%; padding-left: 10%; padding-right: 10%;}
    .section3-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; text-align: center;}
    .section1-row-style{
        margin-bottom: 13%;
    }

    .section5-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; text-align: center;}
    .section3-img-style, .section4-img-style, .section5-img-style {width: auto;}
    /*style the <footer>*/
    .footer-row1-style {border-radius: 25px; background-color: #0000ff; height: 500px; margin: auto;}
    .footer-row1-txt-style {position: relative; bottom: 150px;}
    .SM-img-size {width: 25px;}/*SM Means Social media. This is the styling of social media icons at the footer*/
    .footer-logo {width: 30px;}/*This is the styling victoi logo at the footer*/
    .btn-group-style {margin-left: 30%;}/*style the blue button at the extreme end of the footer*/}
    @media screen and (max-width:290px) {
        .controle{
            padding: 0 0% !important;
            /* border: 1px solid white !important; navbar-brand*/
        }
    }
@media screen and (min-width: 277px) and (max-width: 280px) {
    .footer-row1-txt-style {position: relative; bottom: 100px;}
    .footer-row1-style {height: 500px}
    .H-img{
        padding-bottom:30px;

    }
}
@media screen and (max-width:414px) and (min-width: 375px)  {
    .footer-row1-txt-style {position: relative; margin-top: 300px;}
    .footer-row1-style {height: 600px;}
    .H-img{
        padding-bottom:30px;

    }
}
@media screen and (max-width:820px) and (min-width:769px) {
    .footer-row1-style {height: 350px;}
    .footer-row1-txt-style {position: relative; margin-top: 20px;}
    .img2-style {top: 10px;}
}
@media screen and (max-width:768px) and (min-width:541px) {
    .footer-row1-style {height: 350px;}
    .footer-row1-txt-style {position: relative; margin-top: 20px;}
    .img2-style {top: 10px}
}
@media screen and (max-width:912px) and (min-width: 821px) {
    .footer-row1-style {height: 400px;}
    .footer-row1-txt-style {position: relative; top: 50px;}
    .img2-style {top: 50px}
}
@media screen and (max-width:540px) and (min-width: 415px) {
    .row1-footer-style {height: 650px;}
    .footer-row1-txt-style {position: relative; bottom: 200px}
    .img2-style {bottom: 200px}
    .footStyle{

        margin-right: 10px;
        margin-bottom: 50px;
    }
}
.H-img{
    padding-bottom:30px;
}















/* #nav {
    background: #050719;
}

#nav h2 {
    color: #ffff;
}

.navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navbar h5 {
    color: #ffff;
    padding-left: 30px;
    padding-right: 30px;
}

.navbar Button {
    background:  #8FFF00;
    border: none;
    border-radius: 4px;
    padding: 10px;
    color: #050719;
    font-size: 20px;
    font-style: bold;

}

.navbar NavDropdown {
    background: #050719;
} */