.A-product-navlink {
    background-color: #001;
    color: #0f0;
    border: #0f0 1px solid;
    padding: 5px;
    border-radius: 5px;

}
*{

    font-family: 'Open Sans', sans-serif;
}
.A-nav-headlines {
    font-weight: bold;
    color: #fff;
}
.A-dropdown-menu {
    background-color: #001;
    width: 70%;
    overflow-x: hidden;
}
.A-dropdown-item img {
    width: 60px;
    color: #0f0;
}
.A-dropdown-item {
    color: #0f0;
}
.A-dropdown-menu h3 {
    text-decoration: underline blue; 
    color: #fff; 
}
.A-navbar-download-btn {
    border: #0f0 1px solid;
    border-radius: 5px;
    background-color: #001;
    color: #0f0;
}
.A-navbar-footer {
    text-align: center;
}
.A-navbar-footer h5 {
    color: white;
}
.A-dropdown-menu h4 {
    color: #fff;
}
.A-dropdown-menu li {list-style: none;}


.navbar-brand {
    padding-left: 100px;
}


@media screen and (min-width: 541px){
    .A-hidden {
    display: none;
}
.A-dropdown-menu {
    background-color: #001;
    width: 70%;
    overflow-x: hidden;
}
}
@media screen and (max-width: 540px){
    .A-dropdown-menu {
        width: 100%;
    }

    .navbar-brand {
        padding-left: 0px;
    }
}
@media  screen and  (max-width: 600px) {
    .btnNave{
        background-color: #8fff00;
    }
    .btnOff{
        display: none;
    }

}



