

/* Media Query for devices withi coarse pointers and no hover functionality */

/* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */

/* Container holding the image and the text */
.contain {
    position: relative;
    text-align: center;
    color: white;


}



/* Centered text */

@media screen and (max-width:480px) {

    iframe{
        width: 100%;
        height: 80%;
    }
    .centered{
        width: 100%;
    }


}
@media screen and (max-width:600px) {
    iframe{
        width: 100%;
        height: 90%;
    }


}
@media screen and (min-width:1200px) {
    iframe{
        width: 1000px;
        height: 600px;
    }
    .centered{
        width: 30%;
    }


}