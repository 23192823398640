.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    /* border: 1px solid; */
    margin-bottom: 100px;
}
/* ------head----- */

.head{
    text-align: center;
    margin-bottom: 30px;

}.headText{
    font-weight: bold;
    font-size: 1.2em;
}
.head p{
    color: rgba(255, 255, 255, 0.82);
    font-size: .7em;
}



/* ------/head----- */
/* ------contents----- */
/* ---- this design will take just two pictures at a time for desktop ---- */
.contents{
    margin: 14px auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.content{
    width: 100%;
    max-width: 450px;
    height: 130px;
    display: flex;
    /* border: 2px solid red; */
    margin-bottom: 5px;
}
.contentImage{
    width: 50%;
    height: 100%;
    /* border: 1px solid blue; */
}
.contentImage img{
    width: 95%;
    height: 95%;
    /* margin: 5%; */
    margin-left: 10px;
}
.contentDesc{
    width: 40%;
    right: 0px;
    height: 100%;
    margin-left: 6%;
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 5%; */
}
.descBody{
    font-weight: bold;
    display: -webkit-box;
    color: rgba(255, 255, 255, 0.715);
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.descFoot{
    color: rgba(255, 255, 255, 0.715);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8em;
}
.descHead{
    color: rgba(255, 255, 255, 0.45);

}


/* ------/contents----- */
.viewMore{
    text-align: center;
    margin-bottom: 140px;
    margin-top: 50px;
    color: #8fff00;
    text-decoration: underline;
    cursor: pointer;
}
.pressContact{
    background-color: blue;
}
.pressContact span{
    margin: 24px 32px;
    color: rgb(227, 218, 218);
}
hr{
    color: white;
    width: 90%;
}
.wrapContents{
    display: block;
}

/* --------- LARGER SCREEN ------ */

@media screen and (min-width:750px) {
    .content{
        position: relative;
        width: 370px;
        height: 190px;
        margin: 9px 2vw;
    }
    .contentImage{
        width: 100%;

    }
    .contentImage img{
        
        
    }

    .contentDesc{
        position: absolute;
        width: 100%;
        height: 100%;
        padding:2px 15px;
        background-color: rgba(0, 0, 0, 0.537);
    }
    hr{
        display: none;
    }
    .wrapContents{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;

    }
}

@media screen and (min-width:450px) and (max-width:800px) {
    .container{
        margin-bottom: 200px;
    }
}