.container{
      width: 100%;
      height: 100%;
      margin-top: 150px;
      max-width: 700px;
      margin-bottom: 50px;
      /* color: rgba(255, 255, 255, 0.794); */
}
.container a{
      color: var(--green) !important;
}
.heavy{
      font-weight: 800 !important;
      color: white;
}
@media screen and (max-width:500px) {
      .container{
            padding: 0px 6% !important;
      }
}
