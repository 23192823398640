.main4 {
    /* background-color: #100f25; */
    background-color: #141135;
    color: #ffff;
}
.container4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.p-text4 {
    text-align: center;
    padding: 20px;
}
.p-text4 p {
    padding: 20px;
}

.pagebtn {
    background-color: #8fff00;
    padding: 10px;
    border: 1px solid #050719;
    border-radius: 5px;
    color: #050719;
    font-weight: bolder;
}
