.mgDrop{
    /* border: 2px solid yellow !important; */
    /* height: 100vh; */
    width: 100%;
    padding: 0px !important;
    background-color: '#050719';

}


.btnOff:hover{
    color: #8fff00;
    border-bottom: 2px solid blue;

}
@media screen and (min-width: 600px)  and (max-width: 1000px) {
    .btnOff{
        display: none;
    }

}
@media screen and (min-width: 1509px) and (max-width: 1900px) {
    /* .mgDrop{
        margin-top: 50vh;
        padding-top: -500px;
    } */

}
@media screen and (max-width: 1000px)  {
    .mgDrop{
        height: 200vh;
    }
}
@media screen and (min-width: 1000px)  {
    .btnOff{
        display: none;
    }

    .mgDrop{
        /* height: 70vh !important; */
        margin-top: 10vh !important;
    }

}
.btn.active{
    border-bottom: 1px solid blue;
}
