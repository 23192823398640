section {color: #ffffff; margin-top: 50px;}
.overlay-style {position: relative; bottom: 50px;}
.search-btn-syle {margin: auto;}
.search-button {margin-top: 20px;}
.card-style {border: none;}
/* Style the search field */
form.example input[type=text] { padding: 10px; font-size: 17px; border: 1px solid grey; float: left; width: 80%; background: #f1f1f1;}

/* Style the submit button */
form.example button {float: left; width: 20%; padding: 10px; background: #2196F3; color: white; font-size: 17px; border: 1px solid grey; border-left: none; /* Prevent double borders */ cursor: pointer;}

form.example button:hover {background: #0b7dda;}
/* Clear floats */
form.example::after {content: ""; clear: both; display: table;}

section {color: #ffffff;}
.row2 {width: 60%; margin: auto;}
.overlay-style {position: relative; bottom: 50px;}
.more-videos{text-align: left; margin-top: 16px;}
.more-videos a:link {text-decoration: none; color: #ffffff;}
.more-videos a:link {text-decoration: none;}
.view-videos{text-align: right; margin-top: 16px;}
.view-videos a:link {color: #8fff00;}
.view-videos a:hover {color: #8fff00;}
.view-videos a:active {color: #8fff00;}