@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

.sec-text{
font-size: inherit;
}

.main1 {
    /* background-color: #141135; */
    background-color: var(--bg-second);

    /* background-color: red; */
    color: #ffff;
    padding-bottom: 10%;

}
.specialText1{
    /* color: red; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}


.headTextl{
    font-size:3rem;



}
.sec-text{
    font-weight: bold;
    font-size: 3rem;
    /* font-family: Atma,cursive; */
   


}
.container {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
}

.p-text1 {
    text-align: center;

}
.p-text1 .para {
    color: #98979d;
}


.longtextbtn {
    font-weight: bolder;
    color: #8fff00;
}

@media screen and (max-width: 540px) {

    .p-text1 {

        margin-bottom: 40px;

    }
    .main1{
        margin-top: 73%;
    }

}
@media screen and (max-width: 600px) {
    .p-text1{
        padding-top: 30%;
    }
    .main1{
        margin-top: 82%;
    }



}
@media screen and (min-width: 390px) and (max-width: 540px) {
    .main1{
        margin-top: 95%;

    }


}

@media screen and (min-width: 1024px) {
    .p-text1{
        padding-top: 3%;

    }

}
@media screen and (min-width: 1080px) and (max-width: 1280px) {
    .p-text1{
        padding-top: 5%;
    }

}
@media screen and (min-width: 1281px) and (max-width: 1920px) {
    .main1{
        padding-top: 3%;
    }

}
@media screen and (min-width: 0px) and (max-width: 600px) {
    .headTextl{


        font-size: 1.5rem;




    }
    .sec-text{
        font-size: 1.5rem;


    }


}
@media screen and (min-width: 601px) and (max-width: 1000px) {
    .main1{
        padding-top: 100px;
    }
}

