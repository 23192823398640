.btnNave{
    background-color: red;
    font-weight: bolder;
    display: inline-block;
}

@media screen and (max-width: 1280px) {
    .drop{
        margin-top: 20%;
        color:red;
        background-color: #8fff00;


    }

}
@media screen and (max-width: 600px){
    .drop{

    }
}
