/*STYLING OF SECOND COMPONENT*/

.A-video-section1 {
    position: relative;
    font-size: 3vw;
    color: #ffffff;
    margin-top: 3vw;
    margin-bottom: 3vw;
}
.A-video-section1 .car-racing1 {
    position: relative;
    width: 62%;
    height: 35vw;
    left: 20%;
    right: 20%;
    margin-top: 3vw;
    margin-bottom: 3vw;
}
.A-video-section1 h3,
.A-video-section1 p,
.A-video-section1 .A-search-video {
    text-align: center;
}
.A-video-section1 p {
    font-size: 1.5vw;
}
.A-video-section1 h3 {
    font-size: 2.3vw
}
.A-video-section1 .A-search-video {
    width: 53%;
    left: 24%;
    right: 24%;
    top: 3vw;
}
.A-video-section1 .A-search-video span,
.A-video-section1 .A-search-video input {
    border: 2px solid #0000ff;
    border-radius: 0px;
}
.A-video-section1 .A-search-video span {
    background-color: #0000ff;
    height: 3.8vw;
}
.A-video-section1 .A-search-video input {
    background-color: #039e9c;
    height: 3.8vw;
}
.A-video-section1 .A-search-video input::placeholder {
    text-align: center;
    color: #8fff00;
    font-size: 1.5vw;
  }
.A-video-section1 .A-search-video span img{
    width: 20px;
}


/*STYLING OF NEXT COMPONENT*/
.A-video-section2 {
    position: relative;
    margin-top: 12vw;
    margin-bottom: 5vw;
}
.A-video-section2 .A-video-row .A-right-car-racing2 {
    width: 45%;
    height: 25vw;
    position: relative;
    margin-top: 4vw;
    margin-left: 2vw;
    left: 2%;
    right: 1%;
}
.A-more-videos .A-video-row .A-left-car-racing2 {
    width: 45%;
    height: 25vw;
    position: relative;
    margin-top: 4vw;
    margin-left: 2vw;
    left: 1%;
    right: 2%;
}
.A-more-videos p {
    color: #8fff00;
    text-decoration: underline;
    text-align: center;
    position: relative;
    font-size: 1.5vw;
    margin-bottom: 0px;
    padding-top: 3vw;
}
.A-mobile-video-section2 {
    display: none;
}
@media screen and (max-width: 600px) {
    .main{
        justify-items: center;
    }

    .A-mobile-video-section2 {
        margin-top: 12vw;
        margin-bottom: 12vw;
        position: relative;
        display: inline-block;
    }
    .main{
        margin-top: 80px;
    }
    .react-play{
      width: 320px;
        height: 200px;


    }
    .A-mobile-video-section2 iframe {
        width: 80%;
        height: 80%;
        display: flex;
        justify-items: center;

    }
    .more{
        margin-left: -80px;

    }
    .A-mobile-video-section2{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .A-video-section2,
    .A-more-videos {
        display: none;
    }
    .A-mobile-video-section2 p {
        color: #8fff00;
        text-decoration: underline;
        text-align: center;
        position: relative;
        font-size: 3vw;
        margin-bottom: 0px;
        padding-top: 3vw;
    }
    .A-video-section1 .A-search-video input::placeholder {
        font-size: 2.5vw;
    }
    .A-video-section1 p {
        font-size: 3vw;
    }
    .A-video-section1 h3 {
        font-size: 4vw
    }
    .A-video-section1 .A-search-video span,
    .A-video-section1 .A-search-video input {
    border: 1px solid #0000ff;
    }
}
@media screen and (min-width: 900px) and (max-width: 1280px) {
    .main{
        margin-bottom: -200px;
        padding-top:20px;

    }


}
@media screen and (min-width: 0px) and (max-width: 600px) {
    .A-video-section1 .A-search-video span img{
        width: 10px;
    }

}