
/*SOCIAL MEDIA ICONS STYLES*/
.f-icons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.blue-img2{
    margin-top: -205%;

}

.smicons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    padding-right: 5%;
}

.foot-logo {
    width: 50%;
    display: flex;
    padding-left: 5%;
}

.foot-logo img {
    width: 25%;
}

.connect {
    margin-top: 5%;
    padding-left: 15%;
    text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .f-icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .smicons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        padding-left: 3%;

    }
    .smicons img {
        width: 9%;

    }
    .foot-logo {
        width: 100%;

    }

    .foot-logo img {
        width: 25%;
    }

    .connect {
        text-align: end;
        width: 100%;
        margin-bottom: -9%;
        margin-left: -8%;
    }

}

/*STYLE FOR FOOTER LINKS*/
hr {
    color: white;
}
.A-footer-links {
    margin: auto;
    margin-bottom: -10vw;
}
.A-footer-links .A-select-language {
    padding-left: 5vw;
}
.A-footer-links .A-select-language .form-select{
    width: 50%;
    background-color: #555;
    border: 1px solid #ddd;
    color: white;
    font-size: 1.5vw;
}
.A-footer-links .A-links {
    width: 60%;
    color: #eee;
    font-size: 1.3vw;
    padding-top: 1%;
}
.A-footer-links .A-links ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (min-width: 0px) and (max-width: 600px) {

    /*STYLE FOR FOOTER LINKS*/
    hr {
        color: white;
    }
    .A-footer-links {
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 10%;
    }
    .A-footer-links .A-select-language {
        padding-left: 5vw;
    }
    .A-footer-links .A-select-language .form-select{
        width: 0%;
        border: 1px solid #ddd;
        color: white;
        font-size: 1.5vw;
    }
    .A-footer-links .A-links {
        width: 60%;
        color: #eee;
        font-size: 1.3vw;
        padding-top: 1%;
    }
    .A-footer-links .A-select-language .A-select-input{
        width: 55%;
        font-size: 18px;
    }
    .A-footer-links .A-links ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1900px) and (max-width: 2000px) {
    .A-blue-section {
        width: 80%;
    }
    .A-join-the-revolution .A-app-stores .A-google-store img,
    .A-join-the-revolution .A-app-stores .A-apple-store img {
        width: 15vw;
    }
    .A-blue-section .A-meditation-section img {
        width: 50vw;
        bottom: 18vw;
        margin-right: 12vw;
    }
    /*SOCIAL MEDIA ICONS STYLES*/

}

/*THIS IS THE STYLE OF THE BLUE FOOTER SECTION*/

.bluefoot {
    margin-top: 17%;
    width: 100%;
}

.bluepart {
    width: 85%;
    background: blue;
    margin: auto;
    display: flex;
    flex-direction: row;
    border-radius: 70px;
}

.blue-img2 {
    position: absolute;
    top: 570%;
    left: 3%;
    width: 55%;
}
.blue-img2 img {
    width: 100%;
}

.bluetext {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    margin-left: 55%;
}

.bluetext p {
    font-size: 30px;
    color: #97989d;
}

.textext {
    padding-top: 10px;
}

.textext p {
    margin-top: 10px;
}
.bluelogo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
}
.bluelogo img {
    width: 40% ;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .bluefoot {
        margin-top: 60%;
        width: 100%;
    }
    .textext {
        padding-top: 10px;
        width: 100%;
    }
    .bluepart {
        width: 90%;
        background: blue;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        border-radius: 60px;
    }

    .blue-img2 {
        position: absolute;
        width: 95%;
    }

    .bluetext {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: auto;
        padding-top: 50px;
        width: 100%;
    }
}
@media screen and (min-width: 0px) and (max-width: 360px) {
    .blue-img2 {
        margin-top: -533%;
    }
}

@media screen and (min-width: 361px) and (max-width: 375px) {
    .blue-img2 {
        margin-top: -410%;
    }
}
@media screen and (min-width: 376px) and (max-width: 390px) {
    .blue-img2 {
        margin-top: -650%;
    }
}



@media screen and (min-width: 413px) and (max-width: 414px) {
    .blue-img2 {
        margin-top: -690%;
    }
}
@media screen and (min-width: 393px) and (max-width: 412px) {
    .blue-img2 {
        margin-top: -560%;

    }
}
@media screen and (min-width: 401px) and (max-width: 412px) {
    .blue-img2 {
        margin-top: -662%;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1024px) {
    .blue-img2 {
       margin-top: -146%;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .blue-img2 {
        margin-top: -222%;
    }
}
@media screen and (min-width: 1900px) and (max-width: 1920px) {
    .blue-img2 {
        margin-top: -235%;

    }
}
/*@media screen and (min-width: 300px) and (max-width: 390px) {*/
/*    .bluefoot {*/
/*        margin-top: 60%;*/
/*        width: 100%;*/
/*    }*/
/*    .textext {*/
/*        padding-top: 10px;*/
/*        width: 100%;*/
/*    }*/

/*    .textext p {*/
/*        font-size: 20px;*/

/*    }*/
/*    .bluepart {*/
/*        width: 90%;*/
/*        background: blue;*/
/*        margin: auto;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        border-radius: 60px;*/
/*    }*/

/*    .blue-img img {*/
/*        position: absolute;*/
/*        top: 625%;*/
/*        width: 98%;*/
/*    }*/

/*    .bluetext {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        margin: auto;*/
/*        padding-top: 50px;*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 391px) and (max-width: 420px) {*/
/*    .bluefoot {*/
/*        margin-top: 60%;*/
/*        width: 100%;*/
/*    }*/
/*    .textext {*/
/*        padding-top: 10px;*/
/*        width: 100%;*/
/*    }*/

/*    .textext p {*/
/*        font-size: 20px;*/

/*    }*/
/*    .bluepart {*/
/*        width: 90%;*/
/*        background: blue;*/
/*        margin: auto;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        border-radius: 60px;*/
/*    }*/

/*    .blue-img img {*/
/*        position: absolute;*/
/*        top: 705%;*/
/*        width: 98%;*/
/*    }*/

/*    .bluetext {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        margin: auto;*/
/*        padding-top: 50px;*/
/*        width: 100%;*/
/*    }*/
/*}*/