.A-actual-videos-container {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.A-actual-videos-container .A-main-video-container {
    width: 70%;
    position: fixed;
}

.A-actual-videos-container .A-main-video {
    width: 100%;
    height: 40vw;
    margin-left: 2%;
    background-color: gray;
    position: sticky;
    top: 0px;
    position: relative;
}

.A-actual-videos-container .A-main-video .A-car-racing {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
}

.A-actual-videos-container .A-caption1 {
    background: #070a24;
    position: relative;
}

.A-actual-videos-container .A-caption1 .A-caption-head,
.A-actual-videos-container .A-caption1 .A-caption-txt {
    width: 100%;
    margin-left: 3%;
    margin-top: 0.5vw;
}

/* .A-actual-videos-container .A-caption-head {
    top: 36vw;
}
.A-actual-videos-container .A-caption-txt {
    top: 39vw;
} */
.A-actual-videos-container .A-caption1 .A-caption-head {
    font-size: 2vw;
    font-weight: bold;
}

.A-actual-videos-container .A-caption1 .A-caption-txt {
    font-size: 1.5vw;
}

.A-actual-videos-container button {
    font-size: 1.5vw;
    color: #8fff00;
    border: 1px solid #8fff00;
    background-color: #050719;
    border-radius: 5px;
    width: 15%;
    display: none;
    text-align: center;
    margin-left: 42.5%;
}

.A-actual-videos-container .view-more {
    display: none;
}

.A-actual-videos-container .A-thumbnail-collection {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:end;
    flex-wrap: nowrap;
    overflow-y:visible;
    width: 30%;
    position: absolute;
    right: 0%;
}
.A-actual-videos-container .A-thumbnail-collection .A-thumbnail {
    width: 100%;
    height: 18vw;
}
.A-actual-videos-container .A-thumbnail-collection .A-thumbnail .car-racing {
    width: 100%;
}
.A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption {
    background-color: #050719;
    opacity: 80%;
    height: 30%;
    position: relative;
    bottom: 30%;
}
.A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption .A-caption-head {
    font-size: 1.5vw;
    font-weight: bold;
}
.A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption .A-caption-txt {
    font-size: 1vw;
}
.A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption .A-caption-duration {
    font-size: 1vw;
    font-weight: lighter;
}

@media screen and (min-width: 0px) and (max-width: 540px) {
    .A-actual-videos-container {
        padding-top: 30px;
        width: 100%;
        display: block;
    }
    .A-actual-videos-container .A-main-video-container {
        width: 100%;
        position: fixed;
        top: 0%;
        z-index: 1;
        background-color: #070a24;
        padding-right: 7%;
        padding-top: 5%;
    }
    .A-actual-videos-container .A-main-video {
        width: 100%;
        height: 40vw;
        background-color: gray;
        top: 30%;
        position: relative;
    }
    .A-actual-videos-container .A-caption1 .A-caption-head,
    .A-actual-videos-container .A-caption1 .A-caption-txt {
        width: 100%;
        margin-left: 3%;
        margin-top: 0.5vw;
    }
    .A-actual-videos-container .A-caption1 .A-caption-head {
        font-size: 6vw;
        font-weight: bold;
    }

    .A-actual-videos-container .A-caption1 .A-caption-txt {
        font-size: 3.8vw;
    }
    .A-actual-videos-container .A-thumbnail-collection {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        overflow-y:visible;
        width: 100%;
        position: absolute;
        gap: 12vw;
        right: 0%;
        top: 35vh;
        padding-top: 10vw;
        padding-bottom: 10vw;
    }
    .A-actual-videos-container .A-thumbnail-collection .A-thumbnail {
        width: 100%;
        height: 50vw;
        /* border: 1px solid red; */
    }
    .A-actual-videos-container .A-thumbnail-collection .A-thumbnail .car-racing{
        width: 100%;
        height: 100%;
    }
    .A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption{
        width: 100%;
        height: 45%;
        position: relative;
        bottom: 45%;
        padding-top: 2%;
        padding-left: 2%;
    }
    .A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption .A-caption-head {
        font-size: 5vw;
        font-weight: bold;
    }
    .A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption .A-caption-txt {
        font-size: 4vw;
    }
    .A-actual-videos-container .A-thumbnail-collection .A-thumbnail .A-caption .A-caption-duration {
        font-size: 3.5vw;
        font-weight: lighter;
    }
}
@media screen and (max-width: 600px) {
    .A-actual-videos-container .A-main-video {
        margin-top: 20%;

    }
    .A-actual-videos-container .A-caption1 .A-caption-head {
        margin-left: 13px;
    }
    .A-caption-txt{
        margin-left: 15px;

    }

}