body {
    background-color: #050719;
}



section {
    padding-top: 0%;
    color: #ffffff;
    margin: auto; 
    width: 100%;
}
.contact{
    margin-top: -40px;
    /* border: 2px solid blue; */
}


form {
    margin: auto; 
    width: 100%;
}


input[type=text], input[type=number], input[type=email], input[type=button], .textarea{
    background-color:var(--green);
    border-radius:10px ;
}
.btn-submit {
    background-color: var(--green); color: #ffffff;
}/*class given to submit button*/


.contact-form {
    width: 90%;
    color: #97989d;
}

.contact-form .row {
    padding-top: 30px;
}


.contact-form label {
    font-size: 26px;
}
.form1 {
    width: 100%;
}

.contact-form h2, .contact-form h6 {
    text-align: center;
}

.full-width {
    max-width: 100%;
}

textarea {
    width: 95%; 
    border-radius: 5px;
}

input[type=text] {
    width: 100%;
}

.cont {
    width: 100%;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text h1 {
    font-size: 55px;
    font-weight: 500;
}

.text p {
    color: #97989d;
}
.submitbtn {
    display: flex;
    justify-content: center;
    align-items: center;
}


.submitbtn button {
    padding: 7px;
    width: 120px;
    font-weight: bolder;
    font-size: 20px;
    color: #050719;
}

.submitbtn button:hover {
    border: 2px solid var(--green);
    color: #ffffff;
}

.inp {
    height: 50px;
}

@media screen and (min-width: 375px) and (max-width: 540px) {
    .contact-form {
        width: 90%;
        color: #97989d;
        margin-bottom: 60px;
    }

    .contact-form label {
        font-size: 20px;
    }

    .submitbtn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 200px;
        padding-top: 0%;
    }

    .cont {
        width: 100%;
    }
    
    .text h1 {
        font-size: 40px;
        font-weight: 500;
    }
    .mobile{
        display: flex;
        flex-direction: column;
        width: 210%;
        gap: 35px;
    }
   
    
}

@media screen and (min-width: 450px) and (max-width: 530px) {
    .contact{
        margin-bottom: 100px;
        /* border: 2px solid red; */
    }


}
@media screen and (min-width: 531px) and (max-width: 800px) {
    .contact{
        margin-bottom: 300px;
        /* border: 2px solid green; */
    }


}
@media screen and (min-width: 800px) and (max-width: 1000px) {
    .contact{
        margin-bottom: 150px;
        /* border: 2px solid blue; */
    }


}@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .contact{
        margin-bottom: 150px;
        /* border: 2px solid yellow; */
    }


}
@media screen and (max-width: 375px)  {
    .contact{
        margin-bottom: 150px;
        /* border: 2px solid blue; */
    }


}
/* ------- wider screens ------ */
@media screen and (min-width: 1001px) {
    .contact{
        margin-bottom: 100px;
        /* border: 2px solid pink; */
    }


}