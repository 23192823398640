
.limgContainer{
    display: flex;
    flex-direction: row-reverse;
    margin: 50px 0;
    /* border: 2px solid red !important; */
    /*padding: 100px;*/
}
.limgContainer2{
    display: flex;
    flex-direction: row;
    margin: 50px 0;
    background-color: #141135;
}
.limgContainer .left , .right{
    width: 50%;
     text-align: left;
}.limgContainer2 .left , .right{
    width: 50%;
     text-align: left;
}
.left{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}
.left img{
    width: 100%;
    /* margin: auto; */
}
.headText{
    font-weight: bold;
    font-size:3rem;
}
.right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    padding-left: 6%;
}
.right *{
    margin: 10px auto;
    width: 100%;
}

.special img{
        width: 80%;
        margin: 40px 0;
}
.maintext{
    /* border: 1px solid; */
    padding: 0 9px;
}

@media screen and (max-width: 1000px) {
    .limgContainer {
        flex-direction: column;
        font-size: 1em !important;
    }
    .limgContainer2 {
        flex-direction: column;
        font-size: 1em !important;
    }
    
    .limgContainer  .left,.right{
        width: 100%;
    }.limgContainer2  .left,.right{
        width: 100%;
    }
    
    .headText{
        font-weight: bold;
        text-align: center;
    }
    .linkbt{
        text-align: center;
    }.special img{
        width: 80%;
        /* margin: 40px 0; */
    }
    .right{
        padding-left: 6px;
        padding-right: 6px;

    }
    .pspecial{
        margin-bottom: 30px;
    }
    .maintext{
        text-align: start;
    }
}

@media  screen and (min-width: 200px) and (max-width: 600px) {

    .headText{


        font-size: 1.5rem;

    }
}
