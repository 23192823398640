


@media screen and (min-width: 0) and (max-width: 375px){
    .image2 img {
       right: 25px;
    }
    .textMain{
        padding-top: 10px;
    }

}




.main2 {
    background-color: #141135;
    color: white;
    font-size: large;
}
.main2 *{
    /*overflow: hidden;*/
    /*border: 4px solid blue !important;*/
}


.limg {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    /* border: 1px solid red; */
}

.text2 {
    display: flex;
    flex-direction: column  ;
    justify-content: space-around;
    align-items: start;
    width: 100%;

    /* border: 1px solid pink; */
}

.text2 * {

}


.image2 img {
    width: 39rem;

    /* border: 1px solid blue; */
}
.headtext {

}

.maintext {

    color: #98979d;
}

.btlink {
    margin-top: 15px;
}

.linkbt {
    font-weight: bolder;
    color: #8fff00;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .main2{

        /*margin-top: -26%;*/
        /*padding-top: 10%;*/
        /*border: 3px solid red;*/
        /*padding-bottom: 200px;*/

    }

    .limg {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /*overflow: scroll;*/
        /*border: 3px solid yellow;*/



    }
    .image2 {
        width: 100%;
        max-width: 100%;
        /*margin: auto;*/
        /*margin-bottom: 50px;*/
        /*border: 3px solid blue;*/



    }
    .image2 img {
        width: 100%;
        position: relative;

        top: 15px;
        /*margin-bottom: -150px;*/
    }

    .text2 {
        display: flex;
        align-items: center;
        justify-content: center;
        /*border: 3px solid green;*/

    }
    .headtext {
        text-align: center;
    }
    .maintext {
       text-align: center;
        margin-top:20px;
    }
    .textMain{
        margin-top: -90px;
        margin-bottom: -150px;
    }


    .limg{
        display: flex;
        flex-direction: column;
        /*padding-bottom: 200px;*/
        padding-top: 10px;
        border: 2px solid deeppink;
        /*margin: -20px auto !important;*/
    }
    .my-content{
        display: flex;
        flex-direction: column-reverse;
        /*background-color: red;*/

    }

    .image2{
        margin-bottom: 180px;
        margin-left: -1%;





    }
    .headtext{
        font-size: 8vw;
        font-weight: bold;
        display: flex;
        flex-direction: column;
    }
    .maintext{
        font-size:20px;
    }
}
@media screen and (min-width: 200px) and (max-width: 600px){

    .main2{

        /*margin-top: -180px;*/
        padding-top: 10%;
        /*border: 3px solid red;*/
        /*padding-bottom: 200px;*/

    }

    .limg {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: scroll;
        /*border: 3px solid yellow;*/



    }
    .image2 {
        width: 100%;
        max-width: 100%;
        margin: auto;
        margin-bottom: 50px;
        /*border: 3px solid blue;*/



    }
    .image2 img {
        width: 100%;
        position: relative;

        top: 15px;
        margin-bottom: -150px;
    }

    .text2 {
        display: flex;
        align-items: center;
        justify-content: center;
        /*border: 3px solid green;*/

    }
    .headtext {
        text-align: center;
    }
    .maintext {
        text-align: center;
        margin-top:20px;
    }
    .textMain{
        margin-top: -90px;
        margin-bottom: -150px;
    }

    .limg{
        display: flex;
        flex-direction: column;
        padding-bottom: 200px;
        padding-top: 10px;
        /*margin: -20px auto !important;*/
    }
    .my-content{
        display: flex;
        flex-direction: column-reverse;
        background-color: red;

    }

    .image2{
        margin-bottom: 180px;
        margin-left: -1%;





    }
    .headtext{
        font-size: 8vw;
        font-weight: bold;
        display: flex;
        flex-direction: column;
    }
    .maintext{
        font-size:20px;
    }

}