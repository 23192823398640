

.smcard2 {
    box-shadow: 10px 10px 10px #040408;
    /* elevation: below; */
    display: flex;
    background-color: #0b0d25;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    /* padding: 15px; */
    margin: 2%;
    height: 230px;
    /* border: 11px solid green; */
    width: 270px;
    border-radius: 20px;
    /* margin-bottom: 5%; */
    font-size: .8em;

    /*backdrop-filter: blur(4.9px);*/
    /*-webkit-backdrop-filter: blur(4.9px);*/


}
.smcard2 img{
    margin-top: 20%;
    /* scale: 80%; */
    width: 43%;
    /* height: 70%; */

}
.imgcontainer{
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgcontainer{
    width: 94%;
}
.smcard2 .img{
    /* scale: 80%; */
    margin-top: 20%;
    /* scale: 80%; */
    width: 59% ;
    height: 100%;
    /* border: 1px solid white; */
    color: var(--green);
    /* font-size: 100%; */

} .img *{
    width: 100% !important;
    height: 100% !important;
    text-shadow: 2px 2px 4px black !important;
}


.smcard2 .info {
    color: #98979d;
}

.bigtext2{
    width: 100%;
    height: 40%;
}

@media screen and (max-width: 580px) {

    .smcard2{
        width: 165px;
        /* margin: 18px 8px;  */
        font-size: .4em;
        height: 155px;
        /* border: 1px solid ; */
    }
    
    .smcard2 img{
        width: 60%;
        /* height: 55%; */
    }
    .smcard2 .img{
        width: 55%;
        height: 55%;
    }
    .bigtext2{
        /* height: 20%; */
        font-size: 1.6em !important;
    }



}

@media screen and (min-width: 480px) and (max-width: 590px) {

    /* .smcard2{
        width: 100% !important;
        margin-bottom: 130px;
        border: 1px solid green;
    } */
    .bigtext2{
        /* color: green; */
        font-size: 1.2em !important;
    }
}
