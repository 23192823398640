
body {
    background-color: #050719;
    color: #ffffff;
}

/* Styling for desktop view*/
.A-first-investment1 {
    position: relative;
    overflow-x: hidden;
    top: 3vw;
    bottom: 3vw;
    margin-bottom: 5vw;
}
.A-first-investment1 iframe{
    position: relative;
    width: 74vw;
    height: 42vw;
    left: 13vw;
    right: 13vw;
    margin-top: 40px;
}
.A-first-investment1 h3,
.A-first-investment1 p {
    position: relative;
}
.A-first-investment1 p {
    left: 14vw;
    font-size: 1.5vw;
}
.A-first-investment1 h3 {
    font-size: 2.3vw;
    left: 13.5vw;
    margin-top: 2vw;
}
.A-first-investment2 {
    position: relative;
    overflow-x: hidden;
}
.A-first-investment2 div{
    position: relative;
    width: 90%;
    height: 100%;
    left: 1.5vw;
    overflow: hidden;
    margin-bottom: -5vw;
    margin-left: 7%;

}
.A-first-investment2 iframe {
    width: 25%;
    height: 100%;
    padding-top: 2.5vw;
    padding: 10px;
}
.A-first-investment2 .A-right-heading,
.A-first-investment2 .A-left-heading {
    position: absolute;
    top: 0px;
    font-size: 1.5vw;
}
.A-first-investment2 .A-right-heading {
    right: 1.8vw;
    color: #8fff00;
}
.A-first-investment2 .A-left-heading {
    left: 1.8vw;
}
.A-mobile-first-investment2 {
    display: none;
}
.A-right-heading{
   padding-right: 100px;

}
.A-left-heading{
    padding-left: 60px;

}

/*Styling for mobile view*/
@media screen and  (max-width: 600px) {
    .A-first-investment1 iframe {
        position: relative;
        width: 91vw;
        left: 4.5vw;
        right: 4.5vw;
    }
    .A-first-investment1 p {
        left: 3vw;
        font-size: 1.2vw;
        font-size: 3vw;
    }
    .A-first-investment1 h3 {
        font-size: 2.3vw;
        left: 4.35vw;
        margin-top: 5vw;
        font-size: 5vw;
    }
    .A-first-investment2 {
        display: none;
    }
    .A-mobile-first-investment2 {
        display: inline-block;
        margin-bottom: 5vw;
        margin-top: 12vw;
    }
    .A-mobile-first-investment2 hr{
        position: relative;
        left: 5vw;
    }
    .A-mobile-first-investment2 iframe {
        width: 90vw;
        height: 60vw;
        position: relative;
        left: 5vw;
        right: 5vw;
    }
    .A-mobile-first-investment2 .A-view-more-videos {
        color: #8fff00;
        text-decoration: underline;
        text-align: center;
        position: relative;
        font-size: 3vw;
        margin-bottom: 0px;
        padding-top: 3vw;
    }
}
@media screen and (max-width: 1900px) and (max-width: 1920px) {
    .A-right-heading{
        padding-right: 100px;

    }
    .A-left-heading{
        padding-left: 90px;

    }


}
@media screen and (min-width: 1000px) and (max-width: 1024px) {
    .A-first-investment2{
        margin-bottom: -100px;
        margin-left: -20px;

    }
    .A-right-heading{
        padding-right: 50px;

    }
    .A-left-heading{
        padding-left: 64px;

    }


}