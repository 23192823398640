div .card {

    align-items: center;
    background-color: #1b1b33;
    border: none;
    font-weight: lighter;
    text-align: center;


}
.card-title {
    font-weight: bold;
}
.imgCard {
    width: 5%;
    margin-top:100px;


}
/*all cards imag size*/

@media only screen and (max-width: 768px) {

    .card-body {
        padding: 0.5em 1.2em;
    }
    .card-body .card-text {
        margin: 0;
    }
    .card-img-top {
        width: 8%;
    }

}
@media only screen and (max-width: 1200px) {
    .card-img-top {
        width: 10%;
    }
}