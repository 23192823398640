@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700');
*{
  
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}
/* Color variables definition */
:root {
  --main-green: #49ff00;
  --main-blue: #050719;
  --primary-blue: var(--main-blue);
  --secondary-blue: rgb(255, 255, 255, 0.15);
  /* --secondary-blue: #12162f; */
  --sharp-blue: #0000ff;
  --light-blue: #2d2d70;
  --grey-text: #97989d;
  --white-text: #ffff;
  --transparent-green: rgb(72, 255, 0, 0.15);
  --purple: #6100ff;
  --modal-blue: #141730;
  --transparent-red: rgba(252, 3, 3, 0.605);
} 

:root{
  --font-primary: 1em;
  --bg-second:rgb(218, 217, 228, 0.08);
    /* site colors */
    --green: #49ff00;
    --bg-second: rgb(218, 217, 228, 0.08) ;
    --bg-primary: #050719;
    --color1:#050719;

    /* --color2:#f6f6f6; */
    --color2:var(--bg-primary);
    --color3:#d3d5e0;
    --color4: #49ff00;
    --transparent-green: rgb(72, 255, 0,0.15);
    --transparent-green-hover: rgb(72, 255, 0,0.25);

}
.bg-first{
  background-color: #050719 !important;
}
.bg-second{
  background-color: rgb(218, 217, 228, 0.08) !important;
}
.color-second{
  color: var(--bg-second) !important;
}
.h0{
  font-size: 0.9em;
}
.h1{
  font-size: 1em;
}
.h2{
  font-size: 1.2em !important;
}
.h3{
  font-size: 1.5em !important;
}
.h4{
  font-size: 1.7em !important;
}
.h5{
  font-size: 2em !important;
}
.h6{
  font-size: 2.2em !important;
}
.h7{
  font-size: 2.7em !important;
}
.h8{
  font-size: 3em !important;
}

.f02{
  font-size: 9px !important;

}.f01{
  font-size: .7em !important;

}.f00{
  font-size: .9em !important;

}
.f1{
  font-size: .95em !important;
}
.f2{
  font-size: 1em  !important;
}
.f3{
  font-size: 1.5em !important;
}.f4{
  font-size: 1.7em !important;
}
.f5{
  font-size: 1.9em !important;
}
.f6{
  font-size: 2em !important;
}

.App {
  text-align: center;
}


*{
  font-family: 'poppins' system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;

  /* font-family: 'Open Sans', sans-serif; */
  /* font-size: .9em; */
  transition: .4s;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
