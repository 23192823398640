.specialcase .bcontainer{
      max-width: 25vw !important;
      max-height: 14vw;
      font-size: .8em !important;
      /* border: 11px solid red; */
  }
@media screen and (max-width:800px) {
      .specialcase .bcontainer{
            max-width: 40vw !important;
            max-height: 25vw;
            font-size: .5em !important;
            /* border: 11px solid red; */
        }
}