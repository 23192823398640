/* ---------- blue part in footer ------ */
.footerMain {
    margin-bottom: 0px;
    margin-top: 15vw;
    /* border: 4px solid red; */
}

/* .bluelogo{
    width: 100%;
    border: 1px white !important;
    A-select-language
} */


.bluefootm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 85px 0 10px;
    /* border: 3px solid red; */
    /* padding: 200px 0; */

}

.bluepartm {
    background-color: blue;
    width: 85%;
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    border-radius: 30px;
    /* border: 3px solid red; */
}

.bluetextm {
    position: relative;
    /* border: 1px solid red; */
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4vw 0;
}

.requestm {
    margin-top: 3px;
    height: 45px;
    max-width: 170px;
}

.textext h1 {
    margin: 0px 15px 0 15px;
}

.textext p {

    margin: 0px 15px 30px 20px;
}

.blue-imgm {
    position: relative;
    width: 50%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.A-footer-links {
    width: 85%;
    /* justify-self: center; */
    /* padding-left: 15%; */
    margin: 0px !important;
    justify-content: space-between;
    margin-left: 7.5% !important;
    /* border: 2px solid red; */
}

/* white-hover*/
.wh:hover {
    color: white !important;
    cursor: pointer;
}

.blue-imgm img {
    position: absolute;
    width: 120% !important;
    bottom: 0%;
}

.imgSpecial {
    display: none;
}

/* ---- MOBILE---- */
@media screen and (max-width:800px) {
    .bluelogo {
        width: 100%;
        padding: 0 5px;
    }

    .bluepartm {
        display: flex;
        flex-direction: column;
        /* border: 2px solid red !important; */
        margin-top: 80px;
    }

    .blue-imgm {
        width: 100%;
        position: relative;
        /* position: absolute; */

    }

    .blue-imgm img {
        display: none;
        /* position: absolute;
        right: 20px; */
    }

    .bluetextm {
        width: 100%;
        margin-top: 50px;
        /* border: 1px solid red; */
    }

    .imgSpecial {
        display: block;
        width: 300px;
        position: absolute;
        bottom: 90%;
        /* top: -1%; */
    }
}

/* ---------- /blue part in footer ------ */


/*STYLE FOR FOOTER LINKS*/
hr {
    color: white;
}

.A-footer-links .A-select-language {
    padding-left: 0px !important;
    cursor: pointer;
    transition: .1s;
}

.language-select:hover {
    border: 2px solid;
}

/* .A-footer-links .A-select-language:hover{
    font-weight: bold; iconsContainer
    
} */

.A-footer-links .A-select-language .form-select {
    width: 50%;
    background-color: #555;
    border: 1px solid #ddd;
    color: white;
    font-size: 1.5vw;
}

.A-footer-links .A-links {
    width: 60%;
    color: #eee;
    font-size: 1.3vw;
    padding-top: 1%;
    padding-left: 0px !important;
    margin-left: 0px !important;
    /* border: 3px solid yellow; semi */
}

.A-footer-links .A-links ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid green; icons */
    padding: 0px;
    /* width: 100% !important; */
}



@media screen and (min-width: 0px) and (max-width: 600px) {

    /* responsive size1*/
    .rs1 {
        font-size: 1.5em !important;
    }

    /*STYLE FOR FOOTER LINKS*/
    hr {
        color: white;
    }

    .A-footer-links {
        /* margin: auto; connect */
        margin: 0 0px !important;
        margin-left: 7.5% !important;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 10%;
    }

    .additional-links {
        width: 100% !important;
    }


    .A-footer-links .A-select-language .form-select {
        /*    copy hr */
        border: 1px solid #ddd;
        color: white;
        font-size: 1.5vw;
    }

    .A-footer-links .A-links {
        width: 60%;
        color: #eee;
        font-size: 1.3vw;
        padding-top: 1%;
    }

    .A-footer-links .A-select-language .A-select-input {
        width: 55%;
        font-size: 18px;
    }

    .A-footer-links .A-links ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        justify-content: space-between;
        /* width: 100% !; */
    }

    .A-footer-links .A-links ul li {
        margin: 10px 0;
    }




}


/* ---------added------- wh */
.iconsContainer {
    display: flex;
    /* width: 85%; */
    height: 100%;
    justify-content: center;
    /* align-items: center; */

    /* background-color: pink; */
    flex-direction: column;
    margin: 0 auto;


}

.iconLogo {
    width: 30%;
    display: flex;
    margin-bottom: 10px;
    /* border: 1px solid red; */
}

.iconLogo img {
    width: 130px;
    /* margin-left: 20px; */
}

.iconSemicons {
    width: 70%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
}

.iconSemicons img {
    width: 40px;
    margin-right: 20px !important;
}

.connect1 {
    /* border: 1px solid red; */
    text-align: start;
    /* margin-top: 100px; */
}

.connect1 span {
    position: relative;
    /* left: 34%; */
    /* border: 1px solid red; */
}

.connect2 {
    display: none;
}

.copyRight {
    width: 85%;
}

.terms {
    width: 40%;
}

/* .copyRight1{

} */
.copyRight2 {
    display: none;
}

@media screen and (max-width: 868px) {
    .iconSemicons{
        /* border: 1px solid white !important; */
        width: 100% !important;
    }
    .reduce {
        width: 92% !important;
        /* border: 1px solid white !important; */
    }
    .copyRight2 {
        display: contents;
        width: 85%;
        border: 2px solid red !important;
    }

    .copyRight1 {
        display: none;
    }

    /* .copyRight{
        display: none;
    } */
    .iconsContainer {
        display: block;
        /* background-color: yellow; */
    }

    .iconLogo {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        /* border: 1px solid red; */
    }

    .iconSemicons {
        width: 100%;
    }

    .iconSemicons img {
        width: 40px;
        margin-right: 5px !important;
    }

    .connect1 {
        display: none;
    }

    .connect2 {
        display: contents;
        width: 50%;
        justify-self: flex-end;
        /* background-color: pink; */
        text-align: flex-end;
        padding-left: 100px;
        margin-left: 100px;
    }

    .connect2 .special {
        margin-left: 30px;
        /* border: 11px solid red ; */
        /* background-color: #555; */

    }

    .iconSemicons img {
        width: 33px;
    }

    .footerMain {
        margin-top: 0px;
        margin-bottom: 0px;
        /* border: 2px solid red; */
    }
}

/* -----tablet view----- */
@media screen and (min-width:450px) and (max-width:900px) {
    .imgSpecial {
        width: 500px;
    }

    .footerMain {
        margin-top: 10vw;
        margin-bottom: 0px;
    }
}


@media screen and (min-width: 450px) and (max-width: 614px) {

    .footerMain {
        margin-bottom: 10px;
    }
}
.copyRight1{
    /* border: 1px solid white;  */
    max-width: 45%;
}
/* ---------- wider screens -------- */
@media screen and (min-width: 1108px) {
    .bluetextm {
        /* border: 2px solid yellow; */
        margin: 6.5vw 0;
    }

    /* .footerMain {
        margin-bottom: 70px !important;copyRight1
    } */

    .footerMain {
        margin-bottom: 10px !important;
    }

    /* .A-select-language{
        width: 300px;
    } */


}








/* ---------/added------- */

/*@media screen and (min-width: 300px) and (max-width: 390px) {*/
/*    .bluefoot {*/
/*        margin-top: 60%;*/
/*        width: 100%;*/
/*    }*/
/*    .textext {*/
/*        padding-top: 10px;*/
/*        width: 100%;*/
/*    }*/

/*    .textext p {*/
/*        font-size: 20px;*/

/*    }*/
/*    .bluepart {*/
/*        width: 90%;*/
/*        background: blue;*/
/*        margin: auto;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        border-radius: 60px;*/
/*    }*/

/*    .blue-img img {*/
/*        position: absolute;*/
/*        top: 625%;*/
/*        width: 98%;*/
/*    }*/

/*    .bluetext {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        margin: auto;*/
/*        padding-top: 50px;*/
/*        width: 100%;*/
/*    }*/
/*}*/

@media screen and  (max-width: 420px) {
    .controlt *{
        font-size: .85em !important;
        /* border: 1px solid red !important; */
    }

}
/*@media screen and (min-width: 391px) and (max-width: 420px) {*/
/*    .bluefoot {*/
/*        margin-top: 60%;*/
/*        width: 100%;*/
/*    }*/
/*    .textext {*/
/*        padding-top: 10px;*/
/*        width: 100%;*/
/*    }*/

/*    .textext p {*/
/*        font-size: 20px;*/

/*    }*/
/*    .bluepart {*/
/*        width: 90%;*/
/*        background: blue;*/
/*        margin: auto;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        border-radius: 60px;*/
/*    }*/

/*    .blue-img img {*/
/*        position: absolute;*/
/*        top: 705%;*/
/*        width: 98%;*/
/*    }*/

/*    .bluetext {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-content: center;*/
/*        margin: auto;*/
/*        padding-top: 50px;*/
/*        width: 100%;*/
/*    }*/
/*}*/