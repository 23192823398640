




@media screen and (max-width: 600px){
    .empty-top{
        padding-top: 10%;

    }
}