.container{
      /* border: 1px solid grey; */
      /* border-radius: 25px; */
      width: 500px;
      /* height: max-content; */
}
.collapse2{
      height: 0px !important;
      border: 1px solid grey !important;
      background-color: green !important;
}
.up{
      height: 0px !important;
      border: 1px solid white !important;
}
.down{
      height: 100px !important;
}
.rotate {
      transform: rotateZ(90deg);
}
@media screen and (max-width:450px) {
      .container{
      /* border: 1px solid grey !important; */
      /* border-radius: 25px; */
      width: 95vw;
      /* height: max-content; */
}
      
}