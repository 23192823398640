/* .backContainer{ */
      /* background-color: var(--color3); */
      /* background-color: var(--bg-primary); */
/* } */
.shadow{
      box-shadow: 0px 0px 8px black !important;
      border: 1px solid white !important;
     
}
.blogsContainer{
      box-shadow: 0px 5px 8px black;
      border-radius: 8px;
      min-width: 380px;
      width: 100vw;
      max-width: 480px;
      max-height: 600px;
      overflow: hidden;
      background-color: var(--color2);
      margin: 0 auto;
      color: var(--color3);  
}
.blogsContainer:hover{
      box-shadow: 0px 0px 43px black;
}.shadow:hover{
      box-shadow: 0px 0px 130px white !important;
}

.blogContainer{
      width: 100%;
      max-height: 270px;
      height: 100%;
      overflow: hidden;
}
.blogDate{
      text-align: start !important;

}
.blogImg{
width: 95%;
position: relative;
height: 60%;
}
.blogImg img{
      width: 100%;
      height: 100%;
}
.blogBody{
      width: 100%;
      text-align: start;
      color: var(--color3);
}
.imgOverlay{
      position: inherit;
      bottom: 0px;
      left: 0px;
      width: 96%;
      margin-left: 2%;
}
.imgOverlayContainer{
      position: absolute;
      width: 100%;
      height: 45%;
      background-color: #050719e7;
      bottom: 0px;
}
.blogDesc{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      height: 100%;
      font-size: 1.25em !important;
      
}
.blogDesc *{
      font-size: 1em !important;
}
.blogTitle{
      word-wrap: normal;
      /* border: 2px solid white !important; input */
      /* height: fit-content !important; */
      /* display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden; */
}
.viewmore{
      cursor: pointer;
}
.viewmore:hover{
      scale: 1.1;
}

.blogTitle *{
      
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      
}
.pagination *{
      color: var(--green) !important;
      background-color: transparent ;
      font-weight: 700;
      /* font-size: 2em; */
}
/* .pagination button{
      border: 3px solid var(--green);
      color: white;
} */
/* .pagination button *{
      border: 3px solid var(--green);
      color: white !important;
} */



/* new css */
.bcontainer{
      width: 85vw;
      height: 60vw;
      max-width: 500px;
      max-height: 300px;
      position: relative;
      background-image: url('../../Assets/img/7.gif');
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 10px 10px;
      overflow: hidden;
      box-shadow: 1px 1px 23px black;
      

}
.bcontainer img{
      width: 100%;
      height: 100%;
}
.boverlay{
      position: absolute;
      width: 100%;
      min-height: 50%;
      bottom: 0px;
      background-color: #050719b7;
}

.scrollbar *{
      background-color: var(--green) !important;
      font-weight: 900 !important;
      /* font-size: 2em; */
      color: var(--bg-primary) !important;
}
.scrollbar:hover *{
      scale: 1.1;
}

/* loading animation */
.dot1{
      
      animation-name: l1;
      animation-duration: 3s;
      animation-iteration-count: infinite;
}
.dot2{
      animation-name: l2;
      animation-duration: 3s;
      animation-iteration-count: infinite;
}
.dot3{
      animation-name: l3;
      animation-duration: 3s;
      animation-iteration-count: infinite;
}
@keyframes l1 {
      0%{
            display: none;
      }
      30%{
            display: contents;
      }
      60%{
            display: contents;
      }
      100%{
            display: contents;
      }
}
@keyframes l2 {
      0%{
            display: none;
      }
      30%{
            display: none;
      }
      60%{
            display: contents;
      }
      100%{
            display: contents;
      }
}
@keyframes l3 {
      0%{
            display: none;
      }
      30%{
            display: none;
      }
      60%{
            display: none;
      }
      100%{
            display: contents;
      }
}