.container{
      width: 100vw;
      height: 100vh;
      background-color: #050719;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0px;
      z-index: 9999;
}
.loading{
      color: greenyellow;

}
.loading img{
      width: 90%;
}