.container{
      width: 100vw;
      height: 100vh;
      /* border: 2px solid red; */
      display: flex;
      flex-direction: column;

}
.container *{
      line-height: 1.1 !important;
}
.container a{
      color: var(--green);
}
.container .img{
      width: 17% !important;
      min-width: 40px;
      /* border: 1px solid white; */
}
.container img{
      width: 100%;

      max-width: 100px;
}
.buttons{
      height: 20%;
      max-height: 130px;
}
.set1{

      height: 37%;
      max-height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
}
.set2{

      height: 25%;
      max-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
}
.close{
      height: 8%;
      font-size: 1.35em;
}
.head *{
      font-weight: bold;
}
.head{
      height: 5%;
      display: flex;
      align-items: end;
      /* font-size: 1.5em; */
      font-weight: 900;
}


@media screen and (min-width:1000px) {
      .container{
            display: none;
      }
}