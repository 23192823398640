
.green{
    color: var(--green);
}
.bold{
    font-weight: bold;
}
.vbtn{
    color: var(--bg-primary);
    background-color: var(--green);
    padding: 8px 10px;
    border-radius: 4px 4px;
    font-weight: bolder;
    font-size: 1.1em;
    text-transform: uppercase;
}

.A-modal-container {
    /* height: 100%; */
    font-size: 1em;
    color: white;
    /* border: 1px solid red; */
    
    /* padding-top: 10vh !important; img A-modal-dwn-btn A-col1 */
}
.A-modal-container .hts{
    font-size: 1.3em;
}

.A-modal-container .A-heading {
    font-size: 1.5em;
    font-weight: bold;
    padding-left: 9%;
    /* border: 1px solid red; */
    /* padding-top: 7%; */
}

.A-modal-container .A-row {
    width: 100%;
    margin: auto;
    /* margin-top: -100px !important; */
}

.A-modal-container .A-row .A-subcol1 .col1-img {
    position: relative;
    left: 30%;
    width: 59%;
    
}
.A-modal-container .A-row .A-subcol1 .col2-img {
    position: relative;
    width: 80%;
}
.A-modal-container .A-col2-top-margin-a {
    margin-top: 2.4%;
}
.A-modal-container .A-col2-top-margin-b {
    margin-top: 6%;
}
.A-modal-container .A-col2-top-margin-c {
    margin-top: 7%;
}

.A-modal-container .A-row .A-subrow1 {
    margin-bottom: 2.5%;
}

.A-modal-container h3,
.A-modal-container p {
    color: var(--green);
    margin-top: 3%;
    padding-left: 3%;
}
.A-modal-container h3 {
    font-weight: bold;
    font-size: 2.7vw;
    width: 100%;
}
.A-modal-container p {
    font-size: 1.5vw;
    margin-top: -0.7vw;
}

.A-modal-container .A-col {
    padding-right: 8%;
    padding-right: 0px;
}
.A-modal-container .A-subrow1 {
    width: 100%;
}

.A-modal-container .A-main-modal-heading,
.A-modal-container .A-modal-dwn-btn,
.A-modal-container .A-modal-ourfees-lnk {
    display: none;
}

.A-modal-container .A-mobile-modal {
    display: none;
}
/* ---- mobile --- */
@media screen and (max-width: 1000px) {
    .A-modal-container .A-main-modal-close {
        color:var(--green);
        
    }
    .A-modal-container .A-mobile-modal {
        /* border: 1px solid red; */
        display: block;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly !important;
    }
    .A-modal-container .A-desktop-modal {
        display: none;
    }

    .A-modal-container .A-col1 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 0px;
        padding-left: 1.5vw;
        width: 100% !important;
        /* border: 1px solid red; A-main-modal-heading */

        /*margin-top: -10px;*/
    }
    .A-modal-container{
        /* border: 1px solid yellow; */
        width: 100% !important;
        padding: 0px !important;
    }

    .A-modal-container .A-col1-row img {
        width: 95%;
        /* border: 1px solid pink; */
    }

    .A-modal-container .A-col1-row {
        display: flex;
        flex-direction: row;
        gap: 0px;
        position: relative;
        /* left: 5%; */
        width: 100%;
        margin-top: 0%;
        align-items: center;
        /* border: 2px solid purple; */

        padding-left: 10%;
        /*justify-content: center;*/
    }
    .A-modal-container .A-col1-row .A-icon-ctn {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: end;
        /* border: 2px solid green; */
    }
    .A-modal-container .A-col1-row .A-icon-ctn img{
        position: relative;
        left: -15%;
        width: 40px;
        /* width: 100%; */
    }
    .A-modal-container .A-col1-row .A-txt-ctn {
        width: 100%;
        padding-left: 0px;
        position: relative;
        /* left: -12%; */
    }
    .A-modal-container .A-heading {
        /*font-size: 5vw;*/
        padding-left: 5%;
        position: relative;
        left: -3%;  
        margin-top: 15px;
        /* border: 2px solid; */
    }

    .A-modal-container .A-main-modal-heading,
    .A-modal-container .A-modal-dwn-btn,
    .A-modal-container .A-modal-ourfees-lnk {
        display: block;
    }

    .A-modal-container .A-main-modal-heading {
        /* font-size: 24px; */
        font-size: 1.6em;
        font-weight: bold;
        text-decoration: underline 7px blue;
        padding-left: 3%;
        margin-bottom: 0px;
        /* border: 11px solid pink; A-modal-dwn-btn */
        margin-top: -16px;
        /*padding-top: 20px;*/
    }
    .A-modal-container p {
        /*font-size: 3.4vw;*/
        font-size: .9em;
        /* border: 1px solid pink; */
        width: 100%;
        font-weight:lighter;
        margin-top: -4px;
    }
    .A-modal-container h3 {
        /*font-size: 4.1vw;*/
    /* border: 1px solid yellow; */
        font-size: 1.3em;
    }
    .A-modal-container .A-modal-dwn-btn {
        width: 100%;
        text-align: center;
        margin-top: 25px;
    }
    .A-modal-container .A-modal-dwn-btn button {
        width: 50%;
        /*font-size: 2.9vw;*/
        font-weight: lighter;
        text-align: center;
        border: 1px solid var(--green);
        border-radius: 5px;
        /* color: var(--green);
        background-color: #050719; A-col1-row*/
        
        color: #050719;
        background-color: var(--green);
    }
    .A-modal-container .A-modal-ourfees-lnk {
        width: 80%;
        font-size: 16px;
        text-align: center;
        border-radius: 5px;
        color: rgb(179, 178, 178);
        margin: auto;
        font-weight: bold;
        margin-top: 10px;
    }

}
@media screen and (max-width: 500px) {
    .A-main-modal-heading{
        font-size: 1.6em;
        font-weight: bold;
        /* text-decoration: underline 7px red !important; */
        padding-left: 3%;
        margin-bottom: 0px;
        /* border: 11px solid pink; A-modal-dwn-btn */
        margin-top: 7px !important;
    }
    .A-col1-row p{
        font-weight: 300 ;
    }
    .A-col1-row{
        margin-bottom: 10px;
        font-weight: bold !important;
    }
}
@media screen and (max-width: 382px) {
    .A-main-modal-heading{
        font-size: 1.6em;
        font-weight: bold;
        /* text-decoration: underline 7px red !important; */
        padding-left: 3%;
        margin-bottom: 0px;
        /* border: 11px solid pink; A-modal-dwn-btn */
        margin-top: -10px !important;
    }
    .A-col1-row p{
        font-weight: 300 ;
    }
    .A-col1-row{
        margin-bottom: .1vh;
        font-weight: bold !important;
    }
}
@media screen and (max-width: 300px) {  
     .A-main-modal-heading{
    font-size: 1.6em;
    font-weight: bold;
    /* text-decoration: underline 7px red !important; */
    padding-left: 3%;
    margin-bottom: 0px;
    /* border: 11px solid pink; A-modal-dwn-btn */
    margin-top: -9px !important;
}
.A-col1-row p{
    font-weight: 300 ;
}
.A-col1-row{
    margin-bottom: -6px;
    font-weight: bold !important;
}
.A-modal-dwn-btn button{
    height: 6vh !important;
    width: 90% !important;
}

}
