.A-popular-topics {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: px;
  overflow-x: hidden;
}

.suggestion {
  padding-top: 10px;
  background-color: #0c0928 !important;
  color: white ;
  border: 1px solid var(--green);
}

.suggestion .item {
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: start;
  justify-content: start;
  color: white;
  white-space: nowrap;
  overflow: hidden;
}
.nothing{
  text-align: center;
  color: white;
}
.suggestion .item:hover {
  background-color: rgba(0, 128, 0, 0.281);
}

.suggestion .item span {
  color: var(--green);
  font-weight: 700;
  background-color: inherit;
  border: none;
  text-wrap: nowrap;
}


.suggestion .item:hover span {
  background-color: inherit;
}

.suggestion .item div {
  margin-right: 5px;
  
}

.suggestion img{
  width: 20px;
  height: 20px;
}

.suggestion .item:hover span {
  color: var(--green);
  background-color: transparent;
  border: none;
}




.A-popular-topics table {
  width: 100%;
}

.A-popular-topics p {
  text-align: left;
  color: #97989d;
}

.A-popular-topics h5 {
  text-align: left;
}

.A-popular-topics .col-sm-10 {
  width: 100%;
}

.A-popular-topics h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 40px;
}


.A-popular-topics img {
  width: 40px;
  padding: 1px;
  margin-top: -10px;
}


.A-arrow-img {
  position: relative;
  top: -40px;
}

/* ---------added--------- */
.smallCardContainer {
  justify-content: center;
  background-color: var(--bg-second);
  padding-bottom: 20px;
  margin: 0px !important;
}

/* ---------/added--------- */

@media screen and (max-width: 591px) {
  .A-arrow-img {
    position: relative;
    top: -30px;
  }
.input-group{
  width: 95% !important;
  font-size: .8em;
}

}

@media screen and (max-width:427px) {
#parentContainer h2{
  margin-bottom: 0px !important;
}
  .smallcard1 {
    width: 90%;
    max-width: 140px !important;
  }
  .smallCardContainer{
    padding: 0px !important;
  }
  .suggestion img{
    width: 18px;
    height: 18px;
  }
}

/* the input section */

.A-input h2,
.A-input p,
.A-input div {
  text-align: center;
}

.A-input {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.A-input .input-group {
  position: relative;
  width: 74%;
}

.A-input .input-group input {
  background-color: rgba(25, 163, 25, 0.482);
  color: var(--green);
}

.suggestion {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  /* color: #; */
  z-index: 1000;
}

.A-input p {
  margin-bottom: 40px;
  color: #97989d;
}

.A-input h2 {
  margin-top: 20px;
}

.A-input div {
  margin-bottom: 20px;
}

.A-input div input,
.A-input div span {
  border: 1px blue solid;
  background-color: #477f7f;
  border-radius: 0%;
}

.A-input div span {
  background-color: blue;
  border-radius: 0%;
}

.A-input div span img {
  color: var(--green);
  width: 20px;
}

.A-input div input::placeholder {
  color: var(--green);
  opacity: 1;
}

.A-popular-topics h3 {
  text-align: center;
  color: var(--green);
}

.floating {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 10vh;
  right: 3vw;
  width: 60px;
  z-index: 10;
  height: 40px;
  color: var(--bg-primary);
  background-color: var(--green);
  animation-name: zoomMe;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.floating:hover {
  width: 160px;
  animation: none;
}

.floating .floaticon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 20px auto;
  background-color: inherit;
  border-radius: 50%;
}

.floatdiv {
  overflow: hidden;
  width: 0px;
}

.floating:hover .floatdiv {
  width: auto;
  margin-right: 10px;
}
@keyframes zoomMe {
  0% {
    scale: 1;
    opacity: 1;
  }
  15% {
    scale: 1.4;
    opacity: 1;
  }
  50% {
    scale: .6;
    opacity: .5;
  }
  100% {
    scale: 1;
  }
}

/* @media screen and (min-width: 541px) {
    .A-input {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 10px;
        padding-right: 10px;
    }
    .A-input div { input
        position: absolute;
        width: 50%;
        left: 25%;
    }

    .A-input .input-group {
      border: 1px solid red;
    }
    
} */