/*BREAK POINTS
* 200-600
* 200-375
* 375-414
* 1000-1024
* 1291-1920
* 1080-1280
* 1000-1280

    NEW
*

*/

.main {
    background-color: #050719;
    color: white;
    /*border: 1px solid #050719;*/

}

.rimg {
    display: flex;
    flex-direction: row;
    /*padding-top: 30px;*/
    /*padding-bottom: 30px;*/

    /* border: 1px solid blue; */
}

.text1 {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column  ;

}

.text1 * {
    /* border: 1px solid palevioletred; */
    padding-top: 30px;
}
.image1 img {
    width: 600px;
}

.headtext {

    font-size: 50px;
    font-weight: 600;}

.maintext {
    font-size: x-large;

    color: #98979d;

}

.btlink {
    margin-top: 20px;
}

.linkbt {
    font-weight: bolder;
    color: #8fff00;
}

@media screen and (min-width: 200px) and (max-width: 600px) {
     
    /*.rimg {*/
    /*    display: flex;*/
    /*    flex-direction: column-reverse;*/
    /*    justify-content: space-around;*/
    /*    margin-top: -100px;*/
    /*    overflow: hidden;*/
    /*    margin-bottom: -100px;*/
    /*    align-items: center;*/
    /*    padding-left: 15px !important;*/
    /*    padding-right: 15px !important;*/
    /*    !*border: 2px solid red;*!*/
    /*}*/
    /*.image1 img{*/
    /*    padding-top: -1000px;*/
    /*    margin-bottom: -2000px;*/

    /*}*/
    .linkbt{
        padding-top: -10px;
    }

    .image1 {
        width: 100%;
        max-width: 90%;
        padding-top: 40px;
        border: 3px solid;
        border: 1px solid red;
    }
    .image1 img {
        width: 400px;
        margin-top: 5px;
        margin-bottom: -435px;


    }


    .text1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;

        padding-top: 80%;

    }
    .headtext {
        text-align: center;
        font-weight: bold;
    }
    .maintext {
       text-align: center;
        font-size: 20px;
    }

}
@media screen and (min-width: 200px) and (max-width: 375px) {
    .image1{

        align-items: center;
        width: 88%;

    }
    .image1 img{
        width: 100%;


    }
    .rimg{
        align-items: center;
        margin-bottom: 40px;
    }
    .headtext {

        font-size: 5vw;
        font-weight: bold;
    }
    .maintext {

        font-size: 20px;
    }

}
@media screen and (min-width: 376px) and (max-width: 414px) {

    .image1 img{
        width: 100%;



    }


}
@media screen and (min-width: 414px) and (max-width: 1204px) {

    .image1 img{
        width: 100%;
        /*margin-top: 10%;*/
        /*border: 3px solid red;*/
    }



}

@media screen and (min-width: 1000px) and (max-width: 1024px) {
    .headtext {

        font-weight: bold;
    }
    .maintext {

        font-size: 20px;
    }
    .rimg{
        /*margin-top: 40px;*/
        /*margin-bottom: 120px;*/
    }





}
@media screen and (min-width: 1291px) and (max-width: 1920px){
    .rimg{
        /*margin-top: 50px;*/
        /*margin-bottom: 120px;*/
    }

}

@media screen and (min-width: 1000px) and (max-width: 1280px) {
    .rimg{
        /*margin-top: 50px;*/
        /*margin-bottom: 120px;*/
    }

}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
    .main{

        padding-bottom: 20%;
    }
    .rimg .text1{
        /*margin-top: 2% !important;*/
    }

}
@media screen and (max-width: 1000px) {
    .rimg{
        display: flex !important;
        flex-direction: row !important;
        /*flex-direction: column-reverse !important;*/

        padding-left: 15px !important;
        padding-right: 15px !important;
        /*padding: 30px;*/
        border: 4px solid yellow;
        font-size: 3em;
    }
    .rimg .text1{
        width: 100%;
        /*margin-top: 30px !important;*/
        /*margin-bottom: 200px;*/
        text-align: center;
        border: 2px solid blue;
    }
    .rimg .image1{
        position: relative !important;
        /*margin: -80px auto 0px auto !important;*/
        border: 1px solid red;

    }
    }