.headerCon{
    width: 80%;
}
@media screen and (max-width: 1024px) {
    .navbar{


    }

}
.btnOff:hover{
    color: #8fff00;
    border-bottom: 2px solid #0000ff;

}