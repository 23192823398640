
p {
      /* padding-bottom: 0px !important; */
      /* border: 1px solid white !important; */
      margin: 3px 0px;
}
/* h1,h2,h3,h4,h5{
      border: 1px solid white !important;
      margin: 0px !important;
      padding: 0px !important;
}
br{
      display: none;
} */

.shadow{
      z-index: 1000;
      position: relative;
      /* padding-right: 20px; */
      box-shadow: 0px 1px 50px black !important;
     
}
.shadow2{
      box-shadow: 0px 0px 70px black !important;

}
.blogsContainer{
      box-shadow: 0px 0px 70px black !important;
      border-radius: 10px;
      max-width: 800px;
      overflow: hidden;
      /* border: 1px solid white !important; */
}
.blogsContainer:hover{
scale: 1.01;
}
.blogContainer{
      /* box-shadow: 0px 0px 70px black; */
      /* border: 1px solid grey; */
      width: 94%;
      background-color: black;
      max-width: 800px;     
      border-radius: 15px 15px  ; 
      overflow: hidden;
}
/* .blogContainer:hover{
      box-shadow: 0px 0px 10px var(--green);

} */
.blogImg{
width: 100%;
border: 1px solid var(--bg-primary);
background-color: rgba(63, 90, 113, 0.591);
}
.blogImg img{
      width: 100%;
      /* max-width: 700px; */
      max-height: 500px;
}
.blogBody{
      width: 100%;
      text-align: start;
}

.blogTitle{
      cursor: pointer;
}
.blogLinks{
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      /* border: 1px solid white; */
      justify-content: center;
      /* border: 1px solid; */
}
.social {
      border-radius: 24px;
      background-color: rgba(23, 18, 87, 0.53) !important;
      transition: .4s;
      width: 290px;
      height: 60px;
      color:  var(--green) !important ;
      display: flex;
      justify-content: space-between;
      padding: 10px 30px;
      border: none !important;
}
.social *{
      margin: auto 5px;
      transition: .4s;
      font-size: 1.5em !important;
      color: var(--green) !important;
}
.social *:hover{
      color: rgba(0, 128, 0, 0.808) !important;
}
.social div{
      background-color: transparent !important;
}
.social div:hover{
      color: rgba(0, 128, 0, 0.808) !important;

      /* background-color: rgba(23, 18, 87, 0.53) !important; */
}
.social{
      display: flex;

}
.blogLink{
            margin: .1rem 1rem;
}
/* .shareBtn:hover{
      scale: 1.1;
} */
.buttons button{
      background-color: inherit;
      border: 1px solid var(--green) !important;
      color:  var(--green) !important;
      border-radius: 25px 25px;
      width: 130px;
      text-align: center;
      font-size: .7em !important;
}
.buttons button:disabled{
      background-color: inherit;
      border: 1px solid rgb(7, 77, 7) !important;
      color:  rgb(7, 77, 7) !important;
      border-radius: 25px 25px;
}
.dialog div div {
      background-color: var(--bg-primary) ;
      /* border: 1px solid green; */
}
.dialog div div div{
      border: none;
}
.dialog{
      background-color: rgba(0, 0, 0, 0.717);
      ;
}

.social div:hover *{
      /* background-color: transparent !important; */
      color: rgb(10, 110, 10) !important;
}
.social img{
      width: 30px;
      height: 30px;
}
.social img:hover{
      width: 35px;
      height: 35px;
}


/* new styles  */
.ncontainer{
      width: 100px;
      height: 100px;
      box-shadow: 1 1 20px black;
      margin-top: 200px;
      margin-left: 500px;
      background-color: red;
}
.previewContainer{
      width: 100%;
}
@media screen  and (max-width:500px){
      
.blogImg img{
      width: 100%;
      /* max-width: 700px; */
      max-height: 250px;
}
}
/* @media screen and (max-width:800px) {
      .specialcase .bcontainer{
            max-width: 50px !important;
            border: 11px solid red;
        }
} */