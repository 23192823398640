body {background-color: #050719; color: #ffffff; font-size: 20px;}
.btn-color {background-color: #8fff00; color: #ffffff;}/*edit button on the top navbar*/
nav {max-width: 100%;}
.nav-item-style {position: absolute; right: 10%; background-color: #050719;}/*style links on the top navbar*/
.nav-color {background-color: #050719;}/*add background color to navbar*/
.nav-item-color {color: #ffffff;} /*color of top navbar link in unvisited state*/
.nav-item a:hover {color: #8fff00;}/*color of top navbar link when the used hovers over it*/
.nav-item a:active {color: #8fff00;}/*color of top navbar link when the user clicks it*/
.nav-item a:visited {color: #ffffff;}/*color of top navbar link after it has been clicked*/
.toggler-color {border: none}/*color of navbar dropdown botton on mobile*/
.toggler-color {margin-top: -15px;margin-right: -30px}
.navbar-collapse-style {z-index: 1;}/*cause the top navbar dropdown to be one layer above the section*/
/*style the dropdown page. (The dropdown page is called 'modal'*/
.modal-styles {background-color: #050719;}
.modal-styles h3 {color: #ffffff;}
.modal-styles h4 {color: #8fff00;}
.modal-styles td {color:#8fff00}
.modal-btn {background-color: #000000; color: #8fff00; border: #8fff00 2px solid;}
.modal-position {position: relative; top:100px;}
.modal-foot {text-align: center; display: none;}
.modal-head1 {display: hidden;}
.modal-head1 h3 {text-decoration: underline #0000ff 3px; color:#ffffff; position: relative; left: 3%}
.modal-link-style {font-size: 20px; color: #ffffff; text-decoration: none;}
.modal-styles a {color: #8fff00; text-decoration: none}
.btn-style {color: #8fff00; background-color: #050719;}
/*style the <section>. Note that section1, section2, etc... represent the different rows in the section in order from top to buttom*/
section {position: relative; top: 150px; z-index: 0;}
.container-style {margin: auto;}
/*style search button*/
form.example input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 80%;
  background: #f1f1f1;
}

form.example button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
  }

  form.example button:hover {
background: #0b7dda;
  }

  form.example::after {
    content: "";
    clear: both;
    display: table;
  }
.search-button {height: 25px;}
.section2-row-style {background-color: #1b1b33; padding-top: 5%; padding-left: 10%; padding-right: 10%;}
.section3-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%;}
.section4-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; background-color: #1b1b33;}
.section5-row-style {padding-top: 5%; padding-left: 2%; padding-right: 2%;}
.section3-img-style, .section4-img-style, .section5-img-style {width: 50%;}
.table-borderless-style a {text-decoration: none; color: #ffffff;}
.table-borderless-style a:hover {text-decoration: none; color: #ffffff;}
.table-borderless-style a:active {text-decoration: none;}
.table-borderless-style a:visited {text-decoration: none;}
.col-padding-5 {padding: 5%; font-size:larger}
/*styling of 3 cards (still in the <section>)*/
.cards-color {background-color: #050719;}
.card-img-size {width: 30%;}
.card-shadow {box-shadow: 0 1px 1px 0 #ffffff, 0px 1px 1px 0 #ffffff; margin: 15%}
/*styling of <footer>. Note that the footer has 3 rows. The one with the image and blue background, the one with
social media icons and victoi logo and the one with the links at the and a blue button on the left.*/
footer{position: relative; top: 250px; margin: auto;}
.footer-row1-style {border-radius: 25px; background-color: #0000ff; height: 50vh; margin: auto;}
.footer-row2 {margin: auto; margin-top: 50px; margin-bottom: 50px;}
.footer-logo {width: 70px; margin: auto;}
.btn-group-style {width: 15%; height: 40px;}
.btn-primary-style {border:solid 1px #ffffff}
.SM-style {margin: auto;}
.img2-style {position: relative; width:120%; bottom: 40%; right: 20%;}
.img2-txt-dsc-style {position: relative; bottom: 0%}
.app-download-container-style {border-radius: 10px; background-color:#ffffff; padding: 10px;}
.SM-style {position: relative; left: 0%;}
.footer-row3-style {border-top: solid 1px rgb(255, 255, 255); margin: auto;}
/*additional styles on mobile devices for responsive view*/
@media screen and (max-width:480px) {
    /*styling of entire body*/
    body{font-size: 16px;}
    /*Style navbar and dropdown page*/
    .hide-content {display: none;}/*hide barcode under the top navbar at the page intro on homepage*/
    .modal-foot {display: block;}
    /*style <section> of the page*/
    .section2-row-style {background-color: #1b1b33; padding-top: 5%; padding-left: 10%; padding-right: 10%;}
    .section3-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; text-align: center;}
    .section4-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; background-color: #1b1b33; text-align: center;}
    .section5-row-style {padding-top: 5%; padding-left: 10%; padding-right: 10%; text-align: center;}
    .section3-img-style, .section4-img-style, .section5-img-style {width: auto;}
    /*style the <footer>*/
    .footer-row1-style {border-radius: 25px; background-color: #0000ff; height: 500px; margin: auto;}
    .footer-row1-txt-style {position: relative; bottom: 150px;}
    .SM-img-size {width: 25px;}/*SM Means Social media. This is the styling of social media icons at the footer*/
    .footer-logo {width: 30px;}/*This is the styling victoi logo at the footer*/
    .btn-group-style {margin-left: 30%;}/*style the blue button at the extreme end of the footer*/
}