.container{
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      background-color: transparent;
      
}
.section1{
      width: 100%;
      text-align: center;
}

/* ------- head section ------ */

.headerSection{
      /* border: 1px solid black; */
      width: 100%;
      margin-bottom: 5vw;
}
.headerSecHead{
      font-size: 1.6em;
      font-weight: bold;
}
.headerSecText{
      padding: 0 10px;
}
/* ------- /head section ------ */


/* --------- image section ------- */
.imageSection{
      width: 100%;
}
.imageContainer{
      position: relative;
      min-width: 350px;
      max-width: 700px;
      width: 90%;
      height: 48vw;
      min-height: 150px;
      max-height: 350px;
      margin: 0 auto;
}
.imageContainer img{
      width: 100%;
      height: 100%;
}

.new{
      font-size: 1.4em;
      position: absolute;
      right: 0%;
      top: 15%;
      font-weight: bold;
      padding: 1% 7%;
      color: white;
      background-color: rgba(0, 0, 255, 0.693);
}
.overlay{
      position: absolute;
      text-align: start;
      height: 60%;
      width: 100%;
      bottom: 0%;
      background-color: rgba(0, 0, 0, 0.57);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      

}
.overlayHead{
      font-size: 1.4em;
      font-weight: bold;
      color: white;
      margin: 0 18px ;
}
.overlayBody{
      color: rgba(255, 255, 255, 0.432);
      margin: 0 18px ;


}
.overlayTime{
      color: rgba(255, 255, 255, 0.717);
      margin: 0 18px ;
      font-size: 1.1em;

}
.imageContainer img{
      transform: rotateY(180deg);
}
/* --------- /image section ------- */




/* ------ input section ------ */

.inputContainer{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      min-width: 280px;
      font-weight: bold;
      width: 60%;
      background-color: blue;
      border-radius: 5px;
      margin: 7% auto;
      padding: 2px;
}
.input{
      width: 90%;
      height: 80%;
      outline: none;
      color: green;
      padding: 2px 8px;
      border-radius: 5px;
      text-align: center;
      background-color: rgb(133, 214, 138);
      border: none;
      font-weight: bold;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
}
.inputSearch{
      height: 90%;
      width: 40px;
}

/* ------ /input section ------ */

.section2{
      width: 100%;

      display: flex;
      justify-content: center;
}
/* -------- cards section ------- */
.cardsContainer{
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
}
.card{
      position: relative;
      width: 80%;
      width: 380px;
      height: 200px;
      margin: 2%;
      display: flex;
      align-items: center;
}
.card img{
      width: 100%;
      height: 100%;
}
.card .duration{
      display: none;
}
/* -------- /cards section ------- */

.viewMore{
      margin: 50px auto;
      color: green;
      text-decoration: underline;
      font-size: 1.5em;
      cursor: pointer;
}


hr{
      display: none;
}

@media screen and (max-width:849px) {
      hr{
            display: flex;
            width: 100%;
            margin: 36px 0;
      }
      

      .section1 .overlayHead{
            font-size: 3.5vw;
            margin:0 6px;
      }
      .section1 .overlayBody{
            font-size: 2.5vw;
            margin:0 6px;
      
      
      }
      .section1 .overlayTime{
            color: rgba(255, 255, 255, 0.717);
            margin: 0 18px ;
            margin:0 6px;
            font-size: 3vw;
      
      }
      .section2 .card .overlayHead{
            font-size: 1em;
            margin:0 6px;
      }
      .section2 .card .overlayBody{
            font-size: 1em;
            margin:6 6px;
      
      
      }
      .section2 .card .overlayTime{
            margin:8px 6px;
      
      }
      .new{
            font-size: 1.2em;
      }
      .cardsContainer .overlayHead{
            font-size: 1em;
            margin:0 6px;
      }
      .cardsContainer .overlayBody{
            font-size: .8em;
            margin:0 6px;
      
      
      }
      .cardsContainer .overlayTime{
            color: rgba(255, 255, 255, 0.717);
            margin: 0 18px ;
            margin:0 6px;
            font-size:1em;
      
      }
      .card img{
            width: 50%;
            height: 100%;
      }
      .card{
            display: flex;
            width: 80%;
            min-width: 370px;
            height: 27vw;
            min-height: 13    0px;
            max-height: 200px ;
      }
      .card .duration{
            position: absolute;
            bottom: 0%;
            width: 100%;
            padding-left: 4%;
            display: flex;
            align-items: center;
            justify-content: start;
      }
      .card .overlay{
            position: relative;
            width: 50%;
            height: 100%;
            background-color: transparent ;
      }
      .card .overlay{
            justify-content: center;
      }
}