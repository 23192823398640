@media screen and (min-width: 200px) and (max-width: 375px) {
    .image2{
        margin-left: 40px;
        width: 90%;

    }
    .image2 img{
        width: 100%;

    }

}