.A-video-thumbnail-container {
    padding-top: 60px;
}

.A-video-thumbnail-container .A-top-heading {
    font-size: 2vw;
    font-weight: bold;
    text-align: center;
}
.A-video-thumbnail-container .A-top-txt {
    font-size: 1.2vw;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 3vw;
}
.A-video-thumbnail-container .A-main-video {
    width: 60%;
    height: 35vw;
    margin-left: 20%;
    background-color: #8fff00;
    position: relative;
}
.A-video-thumbnail-container .A-main-video .car-racing {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
}
.A-video-thumbnail-container .A-main-video .A-new {
    color: white;
    background-color: rgb(24, 86, 255);
    font-size: 2.3vw;
    font-weight: bold;
    position: absolute;
    right: 0%;
    width: 20%;
    height: 10%;
    padding-left: 6%;
    top: 5%;
}
.A-video-thumbnail-container .A-main-video .A-caption {
    background-color: #050719;
    opacity: 80%;
    width: 100%;
    height: 40%;
    position: absolute;
    top: 60%;
    right: 0%;
}
.A-video-thumbnail-container .A-caption .A-caption-head,
.A-video-thumbnail-container .A-caption .A-caption-txt,
.A-video-thumbnail-container .A-caption .A-caption-duration {
    width: 100%;
    margin-top: 2%;
    color: white;
}

.A-video-thumbnail-container .A-caption .A-caption-head {
    font-size: 2.3vw;
    font-weight: bold;
}

.A-video-thumbnail-container .A-caption .A-caption-txt {
    font-size: 1.5vw;
}

.A-video-thumbnail-container .A-main-video .A-caption-duration {
    font-size: 1.3vw;
    font-weight: lighter;
}

.A-video-thumbnail-container .A-input-field {
    font-size: 2vw;
    margin-top: 5vw;
    text-align: center;
}

.A-video-thumbnail-container .A-desktop-thumbnail-collection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 5vw;
    height: 60vw;
    padding-top: 5vw;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.A-video-thumbnail-container .A-desktop-thumbnail-collection .A-thumbnail {
    width: 40%;
    height: 23vw;
    background-color: #8fff00;
    position: relative;
}
.A-video-thumbnail-container .A-desktop-thumbnail-collection .A-thumbnail .A-caption {
    background-color: #050719;
    opacity: 80%;
    color: white;
    height: 30%;
    position: absolute;
    bottom: 0%;
    width: 100%;
    padding-left: 3%;
}
.A-video-thumbnail-container .A-desktop-thumbnail-collection .A-thumbnail .A-caption .A-caption-head {
    width: 100%;
    font-size: 1.5vw;
    font-weight: bold;
    position: relative;
    top: 5%;
}
.A-video-thumbnail-container .A-desktop-thumbnail-collection .A-thumbnail .A-caption .A-caption-txt {
    width: 100%;
    font-size: 1.2vw;
    position: relative;
    bottom: 10%;
}
.A-video-thumbnail-container .A-desktop-thumbnail-collection .A-thumbnail .A-caption .A-caption-duration {
    width: 100%;
    font-size: 1vw;
    position: relative;
    font-weight: lighter;
    bottom: 15%;
}
.A-video-thumbnail-container .A-desktop-thumbnail-collection .A-thumbnail .car-racing {
    width: 100%;
    height: 100%;
    position: absolute;
}

.A-video-thumbnail-container .A-mobile-thumbnail-collection {
    display: none;
}

.A-video-thumbnail-container button {
    font-size: 1.5vw;
    color: #8fff00;
    border: 1px solid #8fff00;
    background-color: #050719;
    border-radius: 5px;
    width: 15%;
    display: none;
    text-align: center;
    margin-left: 42.5%;
}

.A-video-thumbnail-container .view-more {
    color: #8fff00;
    width: 100%;
    text-align: center;
    font-size: 1.5vw;
    padding-bottom: 40px;
    margin-top: 20px;
    text-decoration: underline 2px #8fff00;
}

@media screen and (min-width: 0px) and (max-width:540px) {
    .A-video-thumbnail-container .A-top-heading {
        font-size: 5vw;
    }
    .A-video-thumbnail-container .A-top-txt {
        font-size: 3vw;
        margin-bottom: 5vw;
    }
    .A-video-thumbnail-container .A-main-video {
        width: 95%;
        height: 50vw;
        margin-left: 2.5%;
    }
    .A-video-thumbnail-container .A-main-video .A-caption .A-caption-duration {
        font-size: 2.5vw;
        font-weight: lighter;
        margin-top: -0.5vw;
        border: px solid red;
    }
    .A-video-thumbnail-container .A-caption {
        background-color: #050719;
        opacity: 80%;
        width: 100%;
        position: absolute;
        height: 30%;
    }
    .A-video-thumbnail-container .A-caption .A-caption-head,
    .A-video-thumbnail-container .A-caption .A-caption-txt,
    .A-video-thumbnail-container .A-caption .A-caption-duration {
        width: 100%;
        margin-left: 0.3%;
        color: white;
    }
    .A-video-thumbnail-container .A-main-video .A-new {
        color: white;
        background-color: rgb(24, 86, 255);
        font-size: 3.2vw;
        font-weight: bold;
        position: absolute;
        right: 0%;
        width: 20%;
        height: 10%;
        padding-left: 6%;
        padding-top: 0.2%;
        top: 5%;
    }

    .A-video-thumbnail-container .A-caption .A-caption-head {
        font-size: 4vw;
        font-weight: bold;
        margin-top: 1vw;
    }

    .A-video-thumbnail-container .A-caption .A-caption-txt {
        font-size: 2.6vw;
        margin-top: -0.2vw;
    }
    .A-video-thumbnail-container .A-caption .A-caption-duration {
        position: relative;
        top: 1vw;
    }
    .A-video-thumbnail-container .A-input-field {
        font-size: 5vw;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .A-video-thumbnail-container .A-mobile-thumbnail-collection {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 5vw;
        height: 130vw;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail {
        width: 100%;
        height: 25vw;
        border: 0px;
        margin-left: 2.5%;
        padding-bottom: 5vw;
    }
    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-image,
    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-txt {
        width: 50%;
        position: relative;
        height: 125%;
    }
    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-image .car-racing{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0%;
    }
    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-image .A-duration{
        font-size: 3vw;
        font-weight: lighter;
        position: absolute;
        top: 75%;
        left: 15%;
    }
    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-txt {
        background-color: #070a24;
        padding-top: 9%;
    }
    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-txt .A-caption-head {
        font-size: 2.9vw;
        font-weight: bold;
        width: 100%;
    }
    .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-txt .A-caption-txt {
        font-size: 2.4vw;
        width: 100%;
    }
    .A-video-thumbnail-container button {
        font-size: 5vw;
    }
    
    .A-video-thumbnail-container .view-more {
        font-size: 3vw;
    }
    .A-video-thumbnail-container .A-desktop-thumbnail-collection {
        display: none;
    }
    @media screen and (min-width: 0px) and (max-width: 280px) {
        .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-txt {
            background-color: #070a24;
            padding-top: 8%;
        }
        .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-txt .A-caption-head {
            font-size: 2.6vw;
            font-weight: bold;
            width: 100%;
        }
        .A-video-thumbnail-container .A-mobile-thumbnail-collection .A-thumbnail .A-txt .A-caption-txt {
            font-size: 2.1vw;
            width: 100%;
        }
    }
}