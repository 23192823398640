/*BREAK POINTS
* 375-379 GOOD
* 380-540 NOT GOOD
* 1000-1024 NOT GOOD
* 1200-1280 GOOD
* 1280-1920 GOOD
* <600 GOOD
    NEW
* 380-600
* 600-1300

*/



@media screen and (min-width:375px) and (max-width: 379px) {


    .first-card-text{
       margin-bottom: -5px;

        font-size: 14px;
    }
    .cont-class .text-section{


        margin-top: 5%;


    }
    .imgSection{
        margin-top: 94%;
        margin-left: -38%;
        width: 95%;

    }


}
@media screen and (max-width:380px) {
    .imgSection{
        margin-top: 94vw !important;
        margin-left: -38%;
        width: 80%;
        /* border: 11px solid red !important;  text-section*/

    }
    .cont-class{
        padding: 0 20vw;
        margin-bottom: 145vw !important;
    }


}

@media screen and (min-width:380px) and (max-width: 640px) {
    .imgSection{
        margin-top: 55% !important;
        margin-left: -38%;
        width: 90%;

    }


    .cont-class .text-section{


        margin-top: 3%;


    }
    .cont-class{

        margin-bottom: 100vw !important;
    }


}
@media screen and (min-width:1000px) and (max-width: 1199px) {
    .imgSection{
        margin-top: 3%;
        margin-left:  50px;

    }

    .cont-class .text-section{
        margin-left: -30px;
        width: 70%;
        margin-top: 30px;

    }
    .cont-class .text-section .main-text{
        font-size: 34px;


    }
    .cont-class{
        padding-left: -20px;


    }


}
@media screen and (min-width:1200px) and (max-width: 1280px) {
    .imgSection{
        margin-top: 3%;
        margin-left:  50px;





    }
    .cont-class .text-section{
        margin-left: -17px;
        margin-top: 50px;





    }
    .cont-class{
        padding-left: 20px;


    }



}
@media screen and (min-width:1280px) and (max-width: 1920px) {


}
@media screen and (max-width: 660px) {
    .imgSection{
        margin-top: 60%;
        margin-left: -43.5%;
        width: 94%;




    }
    .img{

        margin-left: -3.6%;
        width: 108%;
    }

    .first-card-text Button {

        font-size: 10px;
        margin-bottom: 30px;
    }

}


/************ ADDED THIS **********/
/*TARGETING BREAK POINTS BETWEEN 406-1300 --*/
/*TARGETING BREAK POINTS BETWEEN 380-1300 -*/
/*TARGETING BREAK POINTS BETWEEN 380-600   AND*/
/*TARGETING BREAK POINTS BETWEEN 600-1300*/


@media screen and (min-width: 650px) {
    .main-text{
        padding-right: 20%;
        font-size: 2.4em !important;
        /* border: 4px solid; */
    }
}

@media screen and (min-width: 650px)and (max-width: 1000px) {
    .imgSection{
        /*margin-top: 60%;*/
        /*border: 1px solid red;*/
        margin-bottom: -26%;
        margin-left: -45.5%;
        width: 94%;
        /*border:1px solid red;*/
        position: relative !important;
        /*top: 0;*/


    }
    .text-section{
        width: 100%;
        margin: -8% 0 0 0;
    }
    .cont-class{
        /*border:11px solid pink;*/
        position: relative;
    }
    .img{

        margin-left: -3.6%;
        width: 108%;
    }

    .first-card-text Button {

        font-size: 10px;
        margin-bottom: 30px;
    }

}

/************ /ADDED THIS **********/