.main3 {
    background-color: #050719;
    color: white;
    font-size: larger;
    margin-top: -20px;
    /* border: 1px solid red; */
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 35px;
    top: 20px;
    /* border: 3px solid red; */
}

.text3 {
    display: flex;
    flex-direction: column  ;
    justify-content: space-around;
    align-items: start;
    /* border: 3px solid brown; */
    margin: 4px !important;
}

.headtext3 {
    margin-bottom: 30px;
}

.maintext3 {
    margin-bottom: 30px;
}

.btlink {
    margin-top: 20px;
}

.image3 {
    width: 500px;
    top: 100px;

}
.image3 img {
    width: 400px;
    /* border: 1px solid brown; */
}
.linkbt {
    font-weight: bolder;
    color: #8fff00;
}


@media screen and (min-width: 1040px) and (max-width: 1440px) {
    .text3{
        margin: 50px !important;
        margin-right: -20px !important;
        padding-right: 12px;
        /* border: 1px solid red; */
    }
}

@media screen and (max-width: 1040px) {

    .container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        padding: 0%;
        text-align: center;
        overflow: hidden;
    }
    .main3 .text3 h1, .main .text p {
        padding: 0%;
    }
    .image3 {
        width: 90%;
        max-width: 100%;
        margin:auto;
        display: flex;

    }
    .image3 img {
        /*width: 300px;*/
        width: 100%;
        /*border: 4px solid blue;*/
        margin: 50px auto auto auto;
        position: relative;
        bottom: 30px;
    }

    .text3 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px;
        margin-top: 110px;
        /*border: 1px solid red;*/
    }
    .headtext {
        text-align: center;
    }
    .maintext {
       text-align: center; 
    }
}

/* ------ wider screens ------ */


@media screen and (min-width: 1441px) {
    .text3{
        margin: 50px !important;
        margin-right: 20px !important;
        /* border: 1px solid white; */
    }
}
