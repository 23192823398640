
.cardsss {
    display: flex;
    flex-direction: row;


    /* border: 1px solid red; */
}

.smcard {
    box-shadow: 10px 10px 10px #040408;
    /* elevation: below; */
    display: flex;
    background-color: var(--bg-primary);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
    margin: 21px;
    height: 320px;
    width: 325px;
    border-radius: 20px;
    margin-bottom: 50px;
    /*backdrop-filter: blur(4.9px);*/
    /*-webkit-backdrop-filter: blur(4.9px);*/


}
.smcard:hover{
    /* background: #121436; */
}


.smcard * {
    padding-top: 15px;
}

.smcard .info {
    color: #98979d;
}

@media screen and (max-width: 400px) {

.smcard{
    margin-bottom: 2px !important;
    /* border: 1px solid; */
}
.cardsss{
    padding-bottom: 40px !important;
}
    /*.cardsss {*/
    /*    display: flex;*/
    /*    flex-direction: column !important;*/
    /*    padding: 0%;*/


    /*}*/

    /*.smcard {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    text-align: center;*/
    /*    padding: 15px;*/
    /*    margin:0px !important;*/

    /*    box-shadow: 10px 10px 10px #040408;*/

    /*}*/
    .smcard{
        /* width: 100% !important; */
        /* border: 1px solid red; */
    }



}

@media screen and (min-width: 450px) and (max-width: 800px) {

    .cardsss{
        /* width: 100% !important; */
        margin-bottom: 140px;
        /* border: 1px solid red; */
    }
}
