body {
    background-color: #050719;
    color: #ffffff;
}
.A-press {
    position: relative;
    width: 100%;
}
.A-press h3,
.A-press p,
.A-press .A-blue-btn,
.A-press .A-green-link {
    text-align: center;
}
.A-press p {
    font-size: 1.5vw;
}
.A-press h3 {
    font-size: 3.8vw;
}
.A-press .A-green-link {
    font-size: 1.5vw;
}
.A-press .A-blue-btn {
    background-color: #0000ff;
    color: #ffffff;
    border: 0px;
    font-size: 1.8vw;
    width: 35vw;
    position: relative;
    left: 32.5vw;
    right: 32.5vw;
    margin-top: 12vw;
    margin-bottom: 5vw;
}
.A-press .A-mobile-crypto-emergence {
    display: none;
}
.A-press .A-left-crypto-emergence,
.A-press .A-right-crypto-emergence {
    width: 31vw;
    height: 18.35vw;
}
.A-press .A-left-crypto-emergence {
    position: relative;
    left: 14vw;
    margin-right: 5vw;
    margin-bottom: 2.5vw;
}
.A-press .A-right-crypto-emergence {
    position: relative;
    left: 14vw;
    margin-left: 5vw;
    margin-bottom: 2.5vw;
}
.A-press .A-green-link {
    color: #8fff00;
    text-decoration: underline;
    margin-top: 3vw;
}
.A-press hr {
    display: none;
}

@media screen and (max-width: 375px) {
    .A-press .A-left-crypto-emergence,
    .A-press .A-right-crypto-emergence {
        display: none;
    }
    .A-press .A-mobile-crypto-emergence {
        display: inline-block;
        width: 90vw;
        height: 50vw;
        position: relative;
        left: 5vw;
        margin-bottom: 1vw;
        margin-top: 1vw;
    }
    .A-press hr {
        display: inline-block;
        position: relative;
        width: 90vw;
        left: 5vw;
    }
    .A-press .A-blue-btn{
        width: 98%;
        height: 25px;
        position: relative;
        margin-left: -118px;
        font-size: 15px;
        align-content: center;

    }

}
@media screen and (max-width: 600px) {
    .A-press p{
        font-size: 18px;

    }
    .A-press h3{
        font-size: 30px;

    }
    .A-press .A-green-link {
        font-size: 18px;

    }
}
@media screen and (min-width: 375px) and (max-width: 600px){
    .A-press .A-left-crypto-emergence,
    .A-press .A-right-crypto-emergence {
        display: none;
    }
    .A-press .A-mobile-crypto-emergence {
        display: inline-block;
        width: 90vw;
        height: 50vw;
        position: relative;
        left: 5vw;
        margin-bottom: 1vw;
        margin-top: 1vw;
    }
    .A-press hr {
        display: inline-block;
        position: relative;
        width: 90vw;
        left: 5vw;
    }
    .A-press .A-blue-btn{
        width: 98%;
        height: 25px;
        position: relative;
        margin-left: -31.5%;
        font-size: 15px;
        align-content: center;

    }

}