.container{
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.932);
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0px;
      z-index: 9999;
}
.optionsContainer{
      /* width: 60%; */
      width: 80%;
      min-width: 280px;
      max-width: 400px;
      min-height: 60%;
      background-color: var(--bg-primary);
      /* border: 1px solid; */
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 0px 300px  black;
      border-radius: 14px;
}
.headText{
      color: white;
      text-align: center;
      margin-top: 15px;
      font-size: .9em;
      margin-bottom: 20px;
      color: var(--green);
}
.languageContainer{
      display: flex;
      min-width: 270px;
      width: 90%;
      /* border: 1px solid; */
      margin: 5px;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      /* border: 1px solid; */
      color:var(--green) ;
      font-weight: bold;
      cursor: pointer;
}
.languageBox{
      width: 100%;
      background-color: var(--transparent-green);
      border-radius: 20px;
      text-align: center;
      padding: 7px;
      position: relative;

}
.tick{
      position: absolute;
      right: 10%;
      top: 10%;
}
.languageBox:hover{

      color: var(--green) ;
      background-color: var(--transparent-green-hover);
      border: 1px solid;

}
.border{
      border: 1px solid;
}