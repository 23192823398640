/* style={{display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "100px", paddingTop: "100px"}}

style={{width: "100%", height: "relative", textAlign: "center", border: "2px solid white"}} */

.table-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    

}

table {
    text-align: center;
    width: 80%;
}

table th, table td {
    padding: 10px;
}



.text-up {
    display: flex;
    flex-direction: column;
    padding-top: 200px;
    padding: 20px;
}

.text-up h1 {
    text-align: center;
    font-weight: bolder;
    padding-top: 100px;
}
.text-up p {
}